import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import DateRangePickerMenu from "../../../shared/DateRangePickerMenu";
import { Chat, Construction, Person } from "@mui/icons-material";
import QueriesAnalytics from "./QueriesAnalytics";
import ToolsAnalytics from "./ToolsAnalytics";
import UsersAnalytics from "./UsersAnalytics";
import {
  getEndOfDay,
  getStartOfDay,
  shiftDateBackDays,
} from "../../../utils/dateTime";

export function hashIp(ip: string): string {
  let hash = 0;
  Array.from(ip).forEach((_, i) => {
    let chr = ip.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  });
  return Math.abs(hash).toString();
}

export interface AnalyticsSectionProps {
  startDate: Date;
  endDate: Date;
  dummyData?: boolean;
}

interface AnalyticsSection {
  label: string;
  icon: JSX.Element;
  value: string;
  content: React.FC<AnalyticsSectionProps>;
}

const sections: AnalyticsSection[] = [
  {
    label: "Queries",
    icon: <Chat />,
    value: "queries",
    content: QueriesAnalytics,
  },
  {
    label: "Tool Calls",
    icon: <Construction />,
    value: "tools",
    content: ToolsAnalytics,
  },
  {
    label: "Unique Users",
    icon: <Person />,
    value: "users",
    content: UsersAnalytics,
  },
];

const Analytics = () => {
  const [tab, setTab] = useState("queries");

  const [startDate, setStartDate] = useState(
    getStartOfDay(shiftDateBackDays(new Date(), 29))
  );
  const [endDate, setEndDate] = useState(getEndOfDay(new Date()));

  const ActiveTabContent = sections.find(
    (section) => section.value === tab
  )!.content;

  const [useDummyData, setUseDummyData] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const dummyDataLocalStorage = localStorage.getItem("dummydata");
    const dummyDataUrlParam = urlParams.get("dummydata");
    if (dummyDataUrlParam === "true") {
      localStorage.setItem("dummydata", "true");
      setUseDummyData(true);
      return;
    }
    if (dummyDataUrlParam === "false") {
      localStorage.removeItem("dummydata");
      setUseDummyData(false);
      return;
    } else if (dummyDataLocalStorage === "true") {
      setUseDummyData(true);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <Tabs value={tab} onChange={(e, value) => setTab(value)}>
          {sections.map((section, idx) => (
            <Tab
              key={idx}
              label={section.label}
              value={section.value}
              icon={section.icon}
              iconPosition="start"
              sx={{ minHeight: 0 }}
            />
          ))}
        </Tabs>
        <Box sx={{ p: 1 }}>
          <DateRangePickerMenu
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
        <ActiveTabContent
          startDate={startDate}
          endDate={endDate}
          dummyData={useDummyData}
        />
      </Box>
    </Box>
  );
};

export default Analytics;
