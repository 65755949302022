export function formatDateTime(value: Date) {
    return `${
        value.toLocaleDateString() === new Date().toLocaleDateString()
            ? "Today"
            : value.toLocaleString("default", {
                day: "numeric",
                month: "short",
            }) +
            (new Date().getFullYear() === value.getFullYear()
                ? ""
                : ", " + value.getFullYear())
    }, ${value.toLocaleTimeString("default", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
    })}`;
}

export function shiftDateBackDays(date: Date, days: number) {
    return new Date(new Date(date).setDate(date.getDate() - days));
  }

 export function shiftDateBackMonths(date: Date, months: number) {
    return new Date(new Date(date).setMonth(date.getMonth() - months));
  }

 export function getStartOfDay(date: Date) {
    return new Date(new Date(date).setHours(0, 0, 0, 0));
  }

 export function getEndOfDay(date: Date) {
    return new Date(new Date(date).setHours(23, 59, 59, 999));
  }