import { Box } from "@mui/material";
import NavDrawer from "./nav/NavDrawer";
import Dashboard from "./Dashboard/Dashboard";
import { useAuthContext } from "../contexts/AuthContext";
import Snackbar from "./snackbar/Snackbar";
import Auth from "./Auth/Auth";
import { ReactNode } from "react";
import {
  CustomerContext,
  CustomerContextProvider,
} from "../contexts/CustomerContext";
import Convert from "./Auth/Demo/Convert";

const App = () => {
  const user = useAuthContext();

  let body: ReactNode;
  if (user === null || (user.email && !user.emailVerified)) {
    body = (
      <>
        <Auth />
        <Snackbar />
      </>
    );
  } else {
    body = (
      <>
        <CustomerContextProvider>
          <CustomerContext.Consumer>
            {({ customer }) => (
              <>
                <NavDrawer />
                {!customer.email && <Convert />}
                <Dashboard />
                <Snackbar />
              </>
            )}
          </CustomerContext.Consumer>
        </CustomerContextProvider>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 0,
      }}
    >
      {body}
    </Box>
  );
};

export default App;
