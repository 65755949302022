import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import ModalDialog from "../../../shared/ModalDialog";
import {
  Tool2,
  ToolCreate2,
  ToolUpdate,
  useToolsUpdateToolPatchMutation,
} from "../../../state/layerApi";
import ToolForm from "./ToolForm";

interface EditToolModalProps {
  modalOpen: boolean;
  handleClose: () => void;
  tool: Tool2;
  setTools?: React.Dispatch<React.SetStateAction<Tool2[]>>;
  inApiSpecModal?: boolean;
}

const EditToolModal = ({
  modalOpen,
  handleClose,
  tool,
  setTools,
  inApiSpecModal = false,
}: EditToolModalProps) => {
  const { addMessage } = useSnackbarContext();

  const [editTool] = useToolsUpdateToolPatchMutation();

  async function handleEditTool(values: ToolCreate2) {
    try {
      // TODO: Handle most of this in backend
      // TODO: Fix sending update on empty values -> empty values
      const toolUpdate: ToolUpdate = {};
      if (values.name !== tool.name) toolUpdate.name = values.name;
      if (values.description !== tool.description)
        toolUpdate.description = values.description;
      if (values.method !== tool.method) toolUpdate.method = values.method;
      if (values.url !== tool.url) toolUpdate.url = values.url;
      if (values.strict !== tool.strict) toolUpdate.strict = values.strict;
      if (
        JSON.stringify(values.auth) !== JSON.stringify(tool.auth) &&
        !(values.auth === null && tool.auth === undefined)
      )
        if (values.auth?.length && values.auth.length > 0)
          toolUpdate.auth = values.auth;
        else toolUpdate.auth = null;
      if (
        JSON.stringify(values.params) !== JSON.stringify(tool.params) &&
        !(values.params === null && tool.params === undefined)
      )
        if (values.params?.length && values.params.length > 0)
          toolUpdate.params = values.params;
        else toolUpdate.params = null;
      if (
        JSON.stringify(values.body) !== JSON.stringify(tool.body) &&
        !(values.body === null && tool.body === undefined)
      )
        if (Object.keys(values.body || {}).length > 0)
          toolUpdate.body = values.body;
        else toolUpdate.body = null;
      if (
        JSON.stringify(values.defs) !== JSON.stringify(tool.defs) &&
        !(values.defs === null && tool.defs === undefined)
      )
        if (Object.keys(values.defs || {}).length > 0)
          toolUpdate.defs = values.defs;
        else toolUpdate.defs = null;

      if (Object.keys(toolUpdate).length === 0) {
        handleClose();
        return;
      }

      if (inApiSpecModal && setTools) {
        setTools((prevTools) => {
          const updatedTools = prevTools.map((t) => {
            if (t.id === tool.id) {
              return {
                ...t,
                ...(toolUpdate as Partial<Tool2>),
              };
            }
            return t;
          });
          return updatedTools;
        });
        handleClose();
      } else {
        editTool({
          toolId: tool.id || -1,
          toolUpdate: toolUpdate,
        })
          .unwrap()
          .then(() => {
            addMessage("Tool Updated", "success");
            handleClose();
          })
          .catch((err) => {
            console.error(err);
            addMessage("Failed to update tool", "error");
          });
      }
    } catch (err) {
      console.error(err);
      addMessage("Failed to update tool", "error");
    }
  }

  return (
    <ModalDialog
      label={"Edit Tool"}
      modalOpen={modalOpen}
      handleClose={handleClose}
      hasDefaultWidth
      defaultWidth={600}
    >
      <ToolForm
        onSubmit={(values: ToolCreate2) => handleEditTool(values)}
        submitButtonLabel="Save Changes"
        toolId={tool.id}
        initialValues={{
          name: tool.name || "",
          description: tool.description || "",
          method: tool.method,
          url: tool.url || "",
          auth: tool.auth || null,
          params: tool.params || null,
          body: tool.body || null,
          defs: tool.defs || null,
          strict: tool.strict ?? true,
        }}
      />
    </ModalDialog>
  );
};

export default EditToolModal;
