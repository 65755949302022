import { LoadingButton } from "@mui/lab";
import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import PromptFormGeneral from "./PromptFormGeneral";
import PromptFormSteps from "./PromptFormSteps";
import PromptFormArguments from "./PromptFormArguments";
import {
  Prompt2,
  PromptArgumentCreate,
  PromptCreate,
  PromptStepCreate,
} from "../../../../state/layerApi";

interface ArgumentError {
  name?: string;
  description?: string;
}

interface ArgumentErrors {
  [key: number]: ArgumentError;
}

interface StepErrors {
  [key: number]: string;
}

interface PromptFormErrors {
  name?: string;
  description?: string;
  arguments?: ArgumentErrors;
  steps?: StepErrors;
}

export interface PromptFormTabProps {
  errors: PromptFormErrors;
  validateValues: () => PromptFormErrors;
}

interface PromptFormProps {
  handleSubmit: (prompt: PromptCreate) => void;
  isLoading: boolean;
  initialPrompt?: Prompt2;
  submitButtonLabel?: string;
}

const PromptForm = ({
  handleSubmit,
  isLoading,
  initialPrompt,
  submitButtonLabel,
}: PromptFormProps) => {
  const [name, setName] = React.useState<string>(initialPrompt?.name || "");
  const [description, setDescription] = React.useState<string>(
    initialPrompt?.description || ""
  );
  const [workflowArgumnets, setWorkflowArguments] = React.useState<
    PromptArgumentCreate[]
  >(initialPrompt?.arguments || []);
  const [steps, setSteps] = React.useState<PromptStepCreate[]>(
    initialPrompt?.steps || [{ type: "tool", instruction: "" }]
  );

  const [tab, setTab] = React.useState<"general" | "arguments" | "steps">(
    "general"
  );

  const [errors, setErrors] = React.useState<PromptFormErrors>({});

  function validateValues() {
    const newErrors: PromptFormErrors = {};
    if (!name) {
      newErrors.name = "Name is required";
    }
    if (!description) {
      newErrors.description = "Description is required";
    }
    workflowArgumnets.forEach((argument, idx) => {
      if (!argument.name || argument.name === "") {
        if (!newErrors.arguments) {
          newErrors.arguments = {};
        }
        newErrors.arguments[idx] = {
          name: "Name is required",
          description: "",
        };
      }
      if (!argument.description || argument.description === "") {
        if (!newErrors.arguments) {
          newErrors.arguments = {};
        }
        if (!newErrors.arguments[idx]) {
          newErrors.arguments[idx] = {};
        }
        newErrors.arguments[idx].description = "Description is required";
      }
    });
    steps.forEach((step, idx) => {
      if (!step.instruction || step.instruction === "") {
        if (!newErrors.steps) {
          newErrors.steps = {};
        }
        switch (step.type) {
          case "tool":
            newErrors.steps[idx] = "Tool is required";
            break;
          case "local":
            newErrors.steps[idx] = "Command is required";
            break;
          default:
            newErrors.steps[idx] = "Instruction is required";
            break;
        }
      }
    });
    setErrors(newErrors);
    return newErrors;
  }

  function navigateToErrorTab(errors: PromptFormErrors) {
    const errorTabs = Object.keys(errors).reduce<string[]>(
      (errorTabs: string[], errorKey) => {
        if (errorTabs.includes(errorKey)) {
          return errorTabs;
        }
        switch (errorKey) {
          case "arguments":
            errorTabs.push("arguments");
            break;
          case "steps":
            errorTabs.push("steps");
            break;
          default:
            errorTabs.push("general");
            break;
        }
        return errorTabs;
      },
      []
    );
    if (!errorTabs.includes(tab) && errorTabs.length > 0) {
      setTab(errorTabs[0] as "general" | "arguments" | "steps");
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
        overflow: "hidden",
      }}
    >
      <Tabs
        value={tab}
        onChange={(_, value) => setTab(value)}
        sx={{ borderBottom: "1px solid", borderColor: "divider" }}
      >
        <Tab
          label="General"
          value="general"
          onClick={() => setTab("general")}
        />
        <Tab
          label="Arguments"
          value="arguments"
          onClick={() => setTab("arguments")}
        />
        <Tab label="Steps" value="steps" onClick={() => setTab("steps")} />
      </Tabs>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          maxHeight: "100%",
          overflow: "scroll",
          paddingY: "16px",
        }}
      >
        {tab === "general" && (
          <PromptFormGeneral
            name={name}
            description={description}
            setName={setName}
            setDescription={setDescription}
            errors={errors}
            validateValues={validateValues}
          />
        )}
        {tab === "arguments" && (
          <PromptFormArguments
            workflowArguments={workflowArgumnets}
            setWorkflowArguments={setWorkflowArguments}
            errors={errors}
            validateValues={validateValues}
          />
        )}
        {tab === "steps" && (
          <PromptFormSteps
            steps={steps}
            setSteps={setSteps}
            errors={errors}
            validateValues={validateValues}
          />
        )}
      </Box>
      <LoadingButton
        variant="contained"
        color="primary"
        type="submit"
        loading={isLoading}
        onClick={() => {
          const errors = validateValues();
          if (Object.keys(errors).length > 0) {
            navigateToErrorTab(errors);
            return;
          }
          handleSubmit({
            name: name,
            description: description,
            steps: steps,
            arguments: workflowArgumnets,
          });
        }}
      >
        {submitButtonLabel}
      </LoadingButton>
    </Box>
  );
};

export default PromptForm;
