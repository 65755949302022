import React from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  List,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import pageProps from "../../shared/Pages";
import NavListing from "./NavListing";
import Header from "./Header";
import Footer from "./Footer";
import { useCustomerContext } from "../../contexts/CustomerContext";

const NavDrawer = () => {
  const theme = useTheme();
  const location = useLocation();
  const { customer } = useCustomerContext();
  const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [expanded, setExpanded] = React.useState(!smallScreen);

  return (
    <>
      <Drawer
        PaperProps={{
          style: {
            position: "sticky",
          },
        }}
        variant="permanent"
        open={expanded}
        sx={{
          position: "sticky",
          top: 0,
          height: "100vh",
          maxWidth: "300px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          ...(!expanded && {
            width: "88px",
          }),
        }}
      >
        <Box sx={{ flexGrow: "1" }}>
          <Header expanded={expanded} setExpanded={setExpanded} />
          <Divider />
          <Box sx={{ px: 2, py: 1 }}>
            <List sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {pageProps.map((props, index) => (
                <NavListing
                  key={props.name}
                  expanded={expanded}
                  active={
                    location.pathname === props.path ||
                    (location.pathname === "/" && index === 0)
                  }
                  path={props.path}
                  name={props.name}
                  icon={props.icon}
                  disabled={props.disableOnDemo && !customer.email}
                />
              ))}
            </List>
          </Box>
        </Box>
        <Footer expanded={expanded} />
      </Drawer>
    </>
  );
};

export default NavDrawer;
