import { TextField } from "@mui/material";
import React from "react";
import { PromptFormTabProps } from "./PromptForm";

interface PromptFormGeneralProps extends PromptFormTabProps {
  name: string;
  description: string;
  setName: (name: string) => void;
  setDescription: (description: string) => void;
}

const PromptFormGeneral = ({
  name,
  description,
  setName,
  setDescription,
  errors,
  validateValues,
}: PromptFormGeneralProps) => {
  return (
    <>
      <TextField
        value={name}
        onChange={(e) => {
          if (errors.name) {
            validateValues();
          }
          setName(e.target.value);
        }}
        label="Name"
        fullWidth
        variant="filled"
        required
        helperText={
          errors.name || "A unique and descriptive name for the prompt."
        }
        error={Boolean(errors.name)}
      />
      <TextField
        value={description}
        onChange={(e) => {
          if (errors.description) {
            validateValues();
          }
          setDescription(e.target.value);
        }}
        label="Description"
        fullWidth
        variant="filled"
        required
        helperText={
          errors.description ||
          "A human-readable description of what the prompt does."
        }
        multiline
        error={Boolean(errors.description)}
      />
    </>
  );
};

export default PromptFormGeneral;
