import React from "react";
import { PromptFormTabProps } from "./PromptForm";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { PromptArgumentCreate } from "../../../../state/layerApi";

interface PromptFormArgumentsProps extends PromptFormTabProps {
  workflowArguments: PromptArgumentCreate[];
  setWorkflowArguments: React.Dispatch<
    React.SetStateAction<PromptArgumentCreate[]>
  >;
}

const PromptFormArguments = ({
  workflowArguments,
  setWorkflowArguments,
  errors,
  validateValues,
}: PromptFormArgumentsProps) => {
  return (
    <>
      {workflowArguments.map((argument, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "flex-start",
            borderBottom:
              index === workflowArguments.length - 1 ? 0 : "1px solid",
            borderColor: "divider",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <TextField
                fullWidth
                value={argument.name}
                onChange={(e) => {
                  if (
                    errors.arguments?.[index] &&
                    errors.arguments[index].name
                  ) {
                    validateValues();
                  }
                  setWorkflowArguments((prevArguments) => {
                    return prevArguments.map((a, i) => {
                      if (i === index) {
                        return {
                          ...a,
                          name: e.target.value,
                        };
                      }
                      return a;
                    });
                  });
                }}
                label="Name"
                variant="filled"
                required
                helperText={
                  errors.arguments?.[index] && errors.arguments[index].name
                    ? errors.arguments[index].name
                    : ""
                }
                error={Boolean(
                  errors.arguments?.[index] && errors.arguments[index].name
                )}
              />
            </Box>
            <TextField
              fullWidth
              value={argument.description}
              onChange={(e) => {
                if (
                  errors.arguments?.[index] &&
                  errors.arguments[index].description
                ) {
                  validateValues();
                }
                setWorkflowArguments((prevArguments) => {
                  return prevArguments.map((a, i) => {
                    if (i === index) {
                      return {
                        ...a,
                        description: e.target.value,
                      };
                    }
                    return a;
                  });
                });
              }}
              label="Description"
              variant="filled"
              required
              helperText={
                errors.arguments?.[index] && errors.arguments[index].description
                  ? errors.arguments[index].description
                  : ""
              }
              error={Boolean(
                errors.arguments?.[index] && errors.arguments[index].description
              )}
              multiline
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: index === workflowArguments.length - 1 ? 0 : 2,
              }}
            >
              <Checkbox
                checked={argument.required}
                onChange={(e) => {
                  setWorkflowArguments((prevArguments) => {
                    return prevArguments.map((a, i) => {
                      if (i === index) {
                        return {
                          ...a,
                          required: e.target.checked,
                        };
                      }
                      return a;
                    });
                  });
                }}
              />
              <Typography>Required</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "56px",
            }}
          >
            <IconButton
              onClick={() => {
                if (errors.arguments) {
                  validateValues();
                }
                setWorkflowArguments((prevArguments) =>
                  prevArguments.filter((_, i) => i !== index)
                );
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        </Box>
      ))}
      <Button
        onClick={() => {
          setWorkflowArguments((prevArguments) => [
            ...prevArguments,
            { name: "", description: "", required: false },
          ]);
        }}
        sx={{ display: "flex", alignItems: "center", gap: 1 }}
        size="small"
        variant="outlined"
      >
        <Add fontSize="small" />
        Add Argument
      </Button>
    </>
  );
};

export default PromptFormArguments;
