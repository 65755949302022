import { formatDateTime } from "../../../../utils/dateTime";
import { Button, CircularProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SyncIcon from "@mui/icons-material/Sync";
import { DocumentSource } from "../../../../state/layerApi";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { Source } from "../../../../shared/types";
import DownloadButton from "./DownloadButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";

export function SourcesTableColumns(
  onEditClick: (id: number) => void,
  onSyncClick: (ids: number[]) => void,
  onDeleteClick: (ids: number[]) => void
): GridColDef[] {
  const syncingSources = useSelector(
    (state: RootState) => state.syncingSources.syncingSources
  );
  return [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      sortable: false,
      hideable: false,
    },
    {
      field: "type",
      headerName: "Type",
      valueFormatter: (value: string) => {
        if (value === "website") {
          return "🔗\xa0\xa0Website";
        } else if (value === "document") {
          return "📄\xa0\xa0Document";
        } else if (value === "suggestion") {
          return "❓\xa0\xa0Suggestion";
        } else if (value === "github") {
          return "🐙\xa0\xa0GitHub";
        } else {
          return value;
        }
      },
      sortable: true,
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      flex: 2,
    },
    {
      field: "created_at",
      headerName: "Created On",
      type: "dateTime",
      valueGetter: (value) => value && new Date(value + "Z"),
      valueFormatter: (value?: Date) => {
        if (value == null) {
          return "";
        }
        return formatDateTime(value);
      },
      sortable: true,
    },
    {
      field: "updated_at",
      headerName: "Updated On",
      type: "dateTime",
      valueGetter: (value) => value && new Date(value + "Z"),
      valueFormatter: (value?: Date) => {
        if (value == null) {
          return "";
        }
        return formatDateTime(value);
      },
      sortable: true,
      flex: 1,
    },
    {
      field: "collection_id",
      headerName: "Collection",
      sortable: false,
      hideable: false,
    },
    {
      field: "content",
      headerName: "Content",
      valueGetter: (value, row) => {
        if (row.url) return row.url;
        return value;
      },
      renderCell: (params: GridRenderCellParams<any, string>) => {
        const source = params.row as Source;
        if (source.url) {
          return (
            <a href={source.url} target="_blank" rel="noreferrer">
              {source.url}
            </a>
          );
        } else if (source.type === "document") {
          return (
            <DownloadButton
              id={source.id}
              filename={(source as DocumentSource).filename}
            />
          );
        } else if (source.type === "suggestion") {
          return (
            <>
              <Button
                variant="text"
                size="small"
                sx={{ p: 0, textAlign: "left" }}
                onClick={() => {}}
              >
                Suggestion
              </Button>
            </>
          );
        } else {
          return <>{params.value}</>;
        }
      },
      sortable: true,
      flex: 2,
    },
    {
      field: "actions",
      headerName: "",
      type: "actions",
      sortable: false,
      width: 40,
      getActions: (params) =>
        params.row.url
          ? [
              <GridActionsCellItem
                label={`Sync${
                  syncingSources.includes(params.row.id) ? "ing" : ""
                }`}
                icon={
                  syncingSources.includes(params.row.id) ? (
                    <CircularProgress size={20} />
                  ) : (
                    <SyncIcon />
                  )
                }
                onClick={() => onSyncClick([params.row.id])}
                showInMenu
                disabled={syncingSources.includes(params.row.id)}
              />,
              <GridActionsCellItem
                label="Delete"
                icon={<DeleteIcon />}
                onClick={() => onDeleteClick([params.row.id])}
                showInMenu
              />,
            ]
          : [
              <GridActionsCellItem
                label="Edit"
                icon={<EditIcon />}
                onClick={() => onEditClick(params.row.id)}
                showInMenu
              />,
              <GridActionsCellItem
                label="Delete"
                icon={<DeleteIcon />}
                onClick={() => onDeleteClick([params.row.id])}
                showInMenu
              />,
            ],
    },
  ];
}
