import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const AnalyticsCard = ({
  label,
  data,
  change,
}: {
  label: string;
  data: number;
  change?: number;
}) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 2,
        p: 2,
      }}
    >
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">{data}</Typography>
        {change && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {change >= 0 ? (
              <ArrowUpward color="success" />
            ) : (
              <ArrowDownward color="error" />
            )}
            <Typography
              variant="body2"
              sx={{
                color: change >= 0 ? "success.main" : "error.main",
              }}
            >
              {change}%
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AnalyticsCard;
