import PromptsTable from "./PromptsTable";
import { Box } from "@mui/material";

const Prompts = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid",
        borderColor: "divider",
        overflow: "hidden",
        borderRadius: 1,
      }}
    >
      <PromptsTable />
    </Box>
  );
};

export default Prompts;
