import { Download } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { downloadCSV } from "../../../utils/jsonToCsv";

const AnalyticsGridToolbar = ({
  rows,
  headers,
  tableName,
  startDate,
  endDate,
}: {
  rows: any[];
  headers: string[];
  tableName: string;
  startDate: Date;
  endDate: Date;
}) => {
  return (
    <GridToolbarContainer>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          p: "12px",
          borderBottom: 1,
          borderColor: "divider",
          width: "100%",
        }}
      >
        <GridToolbarQuickFilter
          sx={{ flexGrow: 1, height: "100%" }}
          variant="filled"
        />
        <Button
          variant="contained"
          onClick={() =>
            downloadCSV(
              `${tableName}_${
                startDate.getDate() !== endDate.getDate()
                  ? `${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}`
                  : `${startDate.toLocaleDateString()}`
              }`,
              rows,
              headers
            )
          }
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          size="small"
        >
          <Download fontSize="small" />
          Export as CSV
        </Button>
      </Box>
    </GridToolbarContainer>
  );
};

export default AnalyticsGridToolbar;
