import { Box, Tab, Tabs, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import {
  MCPShareButton,
  MCPInstallationInstructions,
  MCPServerNameField,
  MCPServerCommandField,
  MCPServerArgsField,
} from "./MCPFields";
import {
  VSCodeDescriptionField,
  VSCodeDisplayNameField,
  VSCodeLogoField,
  VSCodeMarketplaceLink,
  VSCodeParticipantDescriptionField,
  VSCodeParticipantNameField,
  VSCodePublishButton,
  VSCodeReadmeField,
} from "./VSCodeFields";
import {
  WebWidgetDisplayNameField,
  WebWidgetInstallationInstructions,
  WebWidgetLogoField,
  WebWidgetPrimaryColorField,
} from "./WebWidgetFields";
import { useCustomerContext } from "../../../contexts/CustomerContext";

interface DeploymentSection {
  label: string;
  value: string;
  fields: DeploymentField[];
  disableOnDemo?: boolean;
}

interface DeploymentField {
  label: string;
  description: string;
  element: React.ReactNode;
  wide?: boolean;
}

const deploymentSections: DeploymentSection[] = [
  // TODO: Add shared assets to backend
  //   {
  //     label: "General",
  //     value: "general",
  //     fields: [
  //       {
  //         label: "Icon",
  //         description:
  //           "This will be displayed as your assistant’s image on most surfaces",
  //         element: <Box>Icon</Box>,
  //       },
  //     ],
  //   },
  {
    label: "MCP",
    value: "mcp",
    fields: [
      {
        label: "Installation Instructions",
        description:
          "Instructions for installing your extension using various MCP clients.",
        element: <MCPInstallationInstructions />,
      },
      {
        label: "Share",
        description:
          "Share this command with developers to install your extension.",
        element: <MCPShareButton />,
      },
      {
        label: "Server Name",
        description:
          "The name of your server as it will appear in the MCP Client.",
        element: <MCPServerNameField />,
      },
      {
        label: "Server Command",
        description:
          "The command used to start your server. If you upload your server as an npm package, this should be npx.",
        element: <MCPServerCommandField />,
      },
      {
        label: "Server Arguments",
        description:
          "The arguments used to start your server. If you upload your server as an npm package, the first argument should be the package name.",
        element: <MCPServerArgsField />,
      },
    ],
  },
  {
    label: "VS Code",
    value: "vscode",
    disableOnDemo: true,
    fields: [
      {
        label: "Marketplace Link",
        description:
          "Once published, this link will take you to your extension in the VS Code marketplace.",
        element: <VSCodeMarketplaceLink />,
      },
      {
        label: "Publish Extension",
        description:
          "Publish your extension to the VS Code marketplace. You will need to republish to see changes to the following fields.",
        element: <VSCodePublishButton />,
      },
      {
        label: "Logo",
        description:
          "This will be displayed as your extensions’s image in the marketplace and in Copilot responses.",
        element: <VSCodeLogoField />,
      },
      {
        label: "Display Name",
        description:
          "The name of your extension as it will appear in the VS Code marketplace",
        element: <VSCodeDisplayNameField />,
      },
      {
        label: "Description",
        description:
          "A brief description of your extension as it will appear in the VS Code marketplace",
        element: <VSCodeDescriptionField />,
      },
      {
        label: "Copilot Participant Name",
        description:
          "This is how your participant will be invoked, prefixed by @ in Copilot chat.",
        element: <VSCodeParticipantNameField />,
      },
      {
        label: "Copilot Participant Description",
        description:
          "This will appear as the placeholder text in Copilot chat while your participant is invoked.",
        element: <VSCodeParticipantDescriptionField />,
      },
      {
        label: "Readme",
        description:
          "A markdown file that will be displayed in the VS Code marketplace",
        element: <VSCodeReadmeField />,
        wide: true,
      },
    ],
  },
  {
    label: "Web Widget",
    value: "web",
    disableOnDemo: true,
    fields: [
      {
        label: "Installation Instructions",
        description:
          "Copy this script before the closing </head> tag of your website to deploy the web widget.",
        element: <WebWidgetInstallationInstructions />,
      },
      {
        label: "Logo",
        description:
          "This will be displayed as your assistant’s image in the top bar and in responses.",
        element: <WebWidgetLogoField />,
      },
      {
        label: "Display Name",
        description: "This will show on the top bar of your web widget.",
        element: <WebWidgetDisplayNameField />,
      },
      {
        label: "Primary Color",
        description:
          "This will be used for various elements on your web widget.",
        element: <WebWidgetPrimaryColorField />,
      },
    ],
  },
];

const Deployments = () => {
  const theme = useTheme();
  const { customer } = useCustomerContext();
  const [tab, setTab] = useState<DeploymentSection>(deploymentSections[0]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
      }}
    >
      <Tabs
        value={tab}
        onChange={(e, value) => setTab(value)}
        sx={{ borderBottom: "1px solid", borderColor: "divider" }}
      >
        {deploymentSections.map((section, idx) =>
          section.disableOnDemo && !customer.email ? (
            <Tooltip
              title={`${section.label} deployment is only available on claimed accounts.`}
              key={idx}
            >
              <span>
                <Tab label={section.label} value={section} disabled />
              </span>
            </Tooltip>
          ) : (
            <Tab key={idx} label={section.label} value={section} />
          )
        )}
      </Tabs>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {tab.fields.map((field, idx) => (
          <Box
            key={idx}
            sx={{
              display: "flex",
              gap: field.wide ? 2 : 8,
              borderBottom: idx < tab.fields.length - 1 ? "1px solid" : "none",
              borderColor: "divider",
              p: 2,
              flexDirection: field.wide ? "column" : "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: field.wide ? "100%" : "300px",
                minWidth: field.wide ? "100%" : "300px",
                maxWidth: field.wide ? "100%" : "300px",
              }}
            >
              <Typography sx={{ fontWeight: "500", width: "100%" }}>
                {field.label}
              </Typography>
              <Typography
                variant="body2"
                color={theme.palette.grey[600]}
                sx={{ width: "100%" }}
              >
                {field.description}
              </Typography>
            </Box>
            {field.element}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Deployments;
