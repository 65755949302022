import { Box, Typography } from "@mui/material";
import { transparentize } from "polished";
import { MethodType } from "../../../state/layerApi";

const MethodTypeBadge = ({ method }: { method: MethodType }) => {
  const colorMap = new Map();
  colorMap.set("GET", "#5691B3");
  colorMap.set("POST", "#679A73");
  colorMap.set("PUT", "#e0973a");
  colorMap.set("PATCH", "#c2b842");
  colorMap.set("DELETE", "#C56767");

  function getColor(method: MethodType) {
    if (colorMap.has(method)) {
      return colorMap.get(method);
    }
    return "#5691B3";
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Box
        sx={{
          px: "8px",
          py: "2px",
          bgcolor: transparentize(0.85, getColor(method)),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "4px",
          justifySelf: "flex-start",
          alignSelf: "center",
          height: "fit-content",
        }}
      >
        <Typography
          sx={{
            height: "fit-content",
            color: getColor(method),
            fontWeight: 600,
          }}
        >
          {method}
        </Typography>
      </Box>
    </Box>
  );
};

export default MethodTypeBadge;
