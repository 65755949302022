import * as React from "react";
import { ListItemButton, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface NavListingProps {
  expanded: boolean;
  active: boolean;
  path: string;
  name: string;
  icon: React.ReactElement;
  disabled?: boolean;
}

const NavListing = ({
  expanded,
  active,
  path,
  name,
  icon,
  disabled,
}: NavListingProps) => {
  const navigate = useNavigate();

  if (expanded) {
    return (
      <Tooltip
        title={`${name} is only available on claimed accounts.`}
        disableInteractive
        enterDelay={500}
        disableFocusListener={!disabled}
        disableHoverListener={!disabled}
        disableTouchListener={!disabled}
      >
        <span>
          <ListItemButton
            disabled={disabled}
            onClick={() => navigate(path)}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              p: 2,
              borderRadius: 2,
              ":hover": {
                backgroundColor: "grey.200",
                cursor: "pointer",
              },
              ...(active && {
                bgcolor: "primary.100",
                color: "primary.main",
                ":hover": { backgroundColor: "primary.200" },
              }),
            }}
          >
            {icon}
            <Typography
              variant="h4"
              sx={{
                fontWeight: active ? "600" : "500",
              }}
            >
              {name}
            </Typography>
          </ListItemButton>
        </span>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        title={
          disabled ? `${name} is only available on claimed accounts.` : name
        }
        disableInteractive
        enterDelay={500}
      >
        <span>
          <ListItemButton
            disabled={disabled}
            onClick={() => navigate(path)}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              p: 2,
              borderRadius: 2,
              ":hover": {
                backgroundColor: "grey.200",
                cursor: "pointer",
              },
              justifyContent: "center",
              ...(active && {
                bgcolor: "primary.100",
                color: "primary.main",
                ":hover": { backgroundColor: "primary.200" },
              }),
            }}
          >
            {icon}
          </ListItemButton>
        </span>
      </Tooltip>
    );
  }
};

export default NavListing;
