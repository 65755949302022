import React from "react";
import {
  PromptStepCreate,
  PromptStepType,
  useSourcesGetSourcesGetQuery,
  useToolsGetToolsGetQuery,
} from "../../../../state/layerApi";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import MethodTypeBadge from "../../Tools/MethodTypeBadge";
import { PromptFormTabProps } from "./PromptForm";

interface PromptFormStepsProps extends PromptFormTabProps {
  steps: PromptStepCreate[];
  setSteps: React.Dispatch<React.SetStateAction<PromptStepCreate[]>>;
}

const PromptFormSteps = ({
  steps,
  setSteps,
  errors,
  validateValues,
}: PromptFormStepsProps) => {
  const { data: tools } = useToolsGetToolsGetQuery();
  const { data: sources } = useSourcesGetSourcesGetQuery();

  return (
    <>
      {steps.map((step, index) => {
        const tool = tools?.find((tool) => tool.name === step.instruction);
        const source = sources?.find(
          (source) => source.id.toString() === step.instruction
        );
        if (step.type === "tool" && step.instruction !== "" && !tool) {
          return <CircularProgress />;
        } else if (
          step.type === "resource" &&
          step.instruction !== "" &&
          !source
        ) {
          return <CircularProgress />;
        }
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "flex-start",
              pb: index === steps.length - 1 ? 0 : 2,
              borderBottom: index === steps.length - 1 ? 0 : "1px solid",
              borderColor: "divider",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <FormControl variant="filled" required sx={{ width: "200px" }}>
                <InputLabel>Type</InputLabel>
                <Select
                  label={"Type"}
                  value={step.type}
                  onChange={(e) => {
                    setSteps((prevSteps) => {
                      return prevSteps.map((s, i) => {
                        if (i === index) {
                          return {
                            ...s,
                            type: e.target.value as PromptStepType,
                          };
                        }
                        return s;
                      });
                    });
                  }}
                >
                  <MenuItem value="tool">Tool</MenuItem>
                  <MenuItem value="resource">Resource</MenuItem>
                  <MenuItem value="text">Text</MenuItem>
                  <MenuItem value="local">Local Action</MenuItem>
                </Select>
              </FormControl>
              {step.type === "tool" && (
                <Autocomplete
                  options={tools || []}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tool"
                      required
                      variant="filled"
                      helperText={errors.steps?.[index]}
                      error={Boolean(errors.steps?.[index])}
                    />
                  )}
                  fullWidth
                  value={tool}
                  renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                      <Box
                        key={key}
                        component={"li"}
                        {...optionProps}
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <MethodTypeBadge method={option.method} />
                        <Typography>{option.name}</Typography>
                      </Box>
                    );
                  }}
                  onChange={(_, value) => {
                    if (errors.steps?.[index]) {
                      validateValues();
                    }
                    setSteps((prevSteps) => {
                      return prevSteps.map((s, i) => {
                        if (i === index) {
                          return {
                            ...s,
                            instruction: value?.name || "",
                          };
                        }
                        return s;
                      });
                    });
                  }}
                />
              )}
              {step.type === "resource" && (
                <Autocomplete
                  options={sources || []}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Resource"
                      required
                      variant="filled"
                      helperText={errors.steps?.[index]}
                      error={Boolean(errors.steps?.[index])}
                    />
                  )}
                  fullWidth
                  value={source}
                  renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                      <Box
                        key={key}
                        component={"li"}
                        {...optionProps}
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <Typography>{option.name}</Typography>
                      </Box>
                    );
                  }}
                  onChange={(_, value) => {
                    if (errors.steps?.[index]) {
                      validateValues();
                    }
                    setSteps((prevSteps) => {
                      return prevSteps.map((s, i) => {
                        if (i === index) {
                          return {
                            ...s,
                            instruction: value?.id.toString() || "",
                          };
                        }
                        return s;
                      });
                    });
                  }}
                />
              )}
              {(step.type === "local" || step.type === "text") && (
                <TextField
                  fullWidth
                  value={step.instruction}
                  onChange={(e) => {
                    if (errors.steps?.[index]) {
                      validateValues();
                    }
                    setSteps((prevSteps) => {
                      return prevSteps.map((s, i) => {
                        if (i === index) {
                          return {
                            ...s,
                            instruction: e.target.value,
                          };
                        }
                        return s;
                      });
                    });
                  }}
                  label={step.type === "text" ? `Instruction` : `Command`}
                  variant="filled"
                  required
                  helperText={errors.steps?.[index]}
                  error={Boolean(errors.steps?.[index])}
                  multiline
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "56px",
              }}
            >
              <IconButton
                onClick={() =>
                  setSteps((prevSteps) =>
                    prevSteps.filter((_, i) => i !== index)
                  )
                }
                disabled={steps.length === 1}
              >
                <Delete />
              </IconButton>
            </Box>
          </Box>
        );
      })}
      <Button
        onClick={() => {
          if (errors.steps) {
            validateValues();
          }
          setSteps((prevSteps) => [
            ...prevSteps,
            { type: "tool", instruction: "", arguments: [] },
          ]);
        }}
        sx={{ display: "flex", alignItems: "center", gap: 1 }}
        size="small"
        variant="outlined"
      >
        <Add fontSize="small" />
        Add Step
      </Button>
    </>
  );
};

export default PromptFormSteps;
