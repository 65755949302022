function convertJSONToCSV(jsonData: any[], columnHeaders: string[]) {
    if (jsonData.length === 0) {
      return '';
    }

    const headers = columnHeaders.join(',') + '\n';

    const rows = jsonData
      .map((row: any) => {
        return columnHeaders.map((field) => row[field] || '').join(',');
      })
      .join('\n');

    return headers + rows;
  }

export function downloadCSV(filename: string, jsonData: any, headers: string[]) {
    const csvData = convertJSONToCSV(jsonData, headers);

    console.log("jsonData", jsonData);
    console.log("headers", headers);
    console.log("csvData", csvData);

    if (csvData === '') {
      console.error('No data to download');
    } else {
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', `${filename}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }