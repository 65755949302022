import copy from "copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
  TextField,
  useTheme,
} from "@mui/material";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import { useCustomerContext } from "../../../contexts/CustomerContext";
import { useEffect, useState } from "react";
import cursorlogo from "../../../assets/cursor.svg";
import claudelogo from "../../../assets/claude.svg";
import windsurflogo from "../../../assets/windsurf.svg";
import twitterlogo from "../../../assets/x-logo.svg";
import {
  McpConfigurationUpdate,
  useMcpConfigurationGetMcpConfigurationGetQuery,
  useMcpConfigurationUpdateMcpConfigurationPatchMutation,
} from "../../../state/layerApi";
import { Add, Delete, Edit, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const MCPBaseField = ({
  fieldName,
  fieldLabel,
  fieldValidation,
  fieldHelperText,
}: {
  fieldName: keyof McpConfigurationUpdate;
  fieldLabel: string;
  fieldValidation?: (value: string) => string | null;
  fieldHelperText?: string;
}) => {
  const { addMessage } = useSnackbarContext();
  const { data: mcpConfig, isLoading } =
    useMcpConfigurationGetMcpConfigurationGetQuery();

  const [value, setValue] = useState<string>("");
  const [editing, setEditing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [updateConfig, { isLoading: isSubmitLoading }] =
    useMcpConfigurationUpdateMcpConfigurationPatchMutation();

  useEffect(() => {
    if (mcpConfig && fieldName in mcpConfig && mcpConfig[fieldName]) {
      setValue(mcpConfig[fieldName] as string);
    }
  }, [mcpConfig]);

  if (isLoading) {
    return <CircularProgress />;
  }

  function handleUpdateField() {
    if (!value) {
      setError("Field cannot be empty");
      return;
    } else if (error === "Field cannot be empty") {
      setError(null);
    }
    if (fieldValidation && fieldValidation(value)) {
      setError(fieldValidation(value));
      return;
    } else if (error && fieldValidation && !fieldValidation(value)) {
      setError(null);
    }
    updateConfig({
      mcpConfigurationId: mcpConfig?.id || 0,
      mcpConfigurationUpdate: {
        [fieldName]: value,
      },
    })
      .unwrap()
      .then(() => setEditing(false))
      .catch(() => addMessage(`Failed to update ${fieldLabel}`, "error"));
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, width: "100%" }}>
      <TextField
        label={fieldLabel}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setError(null);
        }}
        sx={{ flexGrow: 1 }}
        variant="filled"
        disabled={!editing}
        error={Boolean(error)}
        helperText={error ? error : fieldHelperText}
      />
      {editing ? (
        isSubmitLoading ? (
          <CircularProgress />
        ) : (
          <IconButton onClick={handleUpdateField}>
            <Save />
          </IconButton>
        )
      ) : (
        <IconButton onClick={() => setEditing(!editing)}>
          <Edit />
        </IconButton>
      )}
    </Box>
  );
};

export const MCPServerNameField = () => {
  return <MCPBaseField fieldName="server_name" fieldLabel="Server Name" />;
};

export const MCPServerCommandField = () => {
  return (
    <MCPBaseField fieldName="server_command" fieldLabel="Server Command" />
  );
};

export const MCPServerArgsField = () => {
  const { addMessage } = useSnackbarContext();
  const { data: mcpConfig, isLoading } =
    useMcpConfigurationGetMcpConfigurationGetQuery();

  const [values, setValues] = useState<string[]>([""]);
  const [editing, setEditing] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<number, string> | null>(null);

  const [updateConfig, { isLoading: isSubmitLoading }] =
    useMcpConfigurationUpdateMcpConfigurationPatchMutation();

  useEffect(() => {
    if (mcpConfig) {
      setValues(mcpConfig.server_args);
    }
  }, [mcpConfig]);

  useEffect(() => {
    if (
      errors &&
      Object.keys(errors).find((key) => parseInt(key) <= values.length)
    ) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        Object.keys(newErrors).forEach((key) => {
          if (parseInt(key) >= values.length) {
            delete newErrors[parseInt(key)];
          }
        });
        return newErrors;
      });
    }
  }, [values, errors]);

  if (isLoading) {
    return <CircularProgress />;
  }

  function handleUpdateField() {
    let hasErrors = false;
    values.forEach((value, idx) => {
      if (!value) {
        setErrors((prev) => ({ ...prev, [idx]: "Field cannot be empty" }));
        hasErrors = true;
      } else if (errors && errors[idx] === "Field cannot be empty") {
        setErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors[idx];
          if (Object.keys(newErrors).length === 0) {
            hasErrors = false;
          }
          return newErrors;
        });
      }
    });
    if (hasErrors) {
      return;
    }
    updateConfig({
      mcpConfigurationId: mcpConfig?.id || 0,
      mcpConfigurationUpdate: {
        server_args: values,
      },
    })
      .unwrap()
      .then(() => setEditing(false))
      .catch(() => addMessage(`Failed to update Server Arguments`, "error"));
  }

  return (
    <Box
      sx={{ display: "flex", alignItems: "flex-start", gap: 2, width: "100%" }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}
      >
        {values.map((value, idx) => (
          <Box key={idx} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <TextField
              label={"Argument"}
              value={value}
              onChange={(e) => {
                setValues((prev) => {
                  const newValues = [...prev];
                  newValues[idx] = e.target.value;
                  return newValues;
                });
                setErrors((prev) => {
                  const newErrors = { ...prev };
                  delete newErrors[idx];
                  return newErrors;
                });
              }}
              sx={{ flexGrow: 1 }}
              variant="filled"
              disabled={!editing}
              error={errors && errors[idx] ? true : false}
              helperText={errors && errors[idx] ? errors[idx] : ""}
            />
            {editing && (
              <IconButton
                onClick={() => setValues(values.filter((_, i) => i !== idx))}
                disabled={values.length === 1}
              >
                <Delete />
              </IconButton>
            )}
          </Box>
        ))}
        {editing && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 2,
              width: "100%",
              pr: 7,
            }}
          >
            <Button
              onClick={() => setValues([...values, ""])}
              size="small"
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Add />
              Add Argument
            </Button>
            <LoadingButton
              onClick={handleUpdateField}
              loading={isSubmitLoading}
              sx={{ display: "flex", gap: 1, alignItems: "center" }}
              variant="contained"
              size="small"
            >
              <Save />
              Save
            </LoadingButton>
          </Box>
        )}
      </Box>
      {!editing && (
        <IconButton onClick={() => setEditing(!editing)}>
          <Edit />
        </IconButton>
      )}
    </Box>
  );
};

export const MCPShareButton = () => {
  const { customer } = useCustomerContext();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <a
        href={`https://twitter.com/share?text=I made this mcp server in a couple minutes using Layer.  Try it out with this command: npx @buildwithlayer/layer-mcp-demo --Layer-Api-Key=${customer.api_key}`}
        target="_blank"
        rel="noreferrer"
        style={{
          borderRadius: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          padding: "8px",
          height: "fit-content",
        }}
      >
        <img src={twitterlogo} width={16} height={16} alt="Twitter Logo" />
      </a>
    </Box>
  );
};

export const MCPInstallationInstructions = () => {
  const { addMessage } = useSnackbarContext();
  const theme = useTheme();

  const grey100 = theme.palette.grey[100];

  const [tab, setTab] = useState<string>("cursor");

  const { data: mcpConfig, isLoading } =
    useMcpConfigurationGetMcpConfigurationGetQuery();

  if (isLoading || !mcpConfig) {
    return <CircularProgress />;
  }

  const config = JSON.stringify(
    {
      mcpServers: {
        [mcpConfig.server_name]: {
          command: mcpConfig.server_command,
          args: [
            ...mcpConfig.server_args,
            `--Layer-Client=${tab.slice(0, 1).toUpperCase() + tab.slice(1)}`,
          ],
        },
      },
    },
    null,
    2
  );

  return (
    <Box sx={{ backgroundColor: grey100, borderRadius: "4px", width: "100%" }}>
      <Tabs
        value={tab}
        onChange={(_, value) => setTab(value)}
        sx={{ borderBottom: "1px solid", borderColor: "divider" }}
      >
        <Tab
          label="Cursor"
          value={"cursor"}
          icon={
            <img src={cursorlogo} width={24} height={24} alt="Cursor Logo" />
          }
          iconPosition="start"
          sx={{ minHeight: 0 }}
        />
        <Tab
          label="Claude"
          value={"claude"}
          icon={
            <img src={claudelogo} width={24} height={24} alt="Claude Logo" />
          }
          iconPosition="start"
          sx={{ minHeight: 0 }}
        />
        <Tab
          label="Windsurf"
          value={"windsurf"}
          icon={
            <img
              src={windsurflogo}
              width={24}
              height={24}
              alt="Windsurf Logo"
            />
          }
          iconPosition="start"
          sx={{ minHeight: 0 }}
        />
      </Tabs>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {tab === "cursor" && (
          <p>
            1. Open <strong>Cursor Settings</strong> → <strong>MCP</strong>
            <br />
            2. Click Add New MCP Server
            <br />
            3. Replace the file contents with the following
          </p>
        )}
        {tab === "claude" && (
          <p>
            1. Open <strong>Claude Settings</strong> →{" "}
            <strong>Developer</strong>
            <br />
            2. Click "Edit Config"
            <br />
            3. Replace the file contents with the following
          </p>
        )}
        {tab === "windsurf" && (
          <p>
            1. Open <strong>Windsurf Settings</strong> →{" "}
            <strong>General</strong> → <strong>MCP Servers</strong>
            <br />
            2. Click Add New MCP Server
            <br />
            3. Replace the file contents with the following
          </p>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Button
            size="small"
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              px: 1.5,
              py: 1,
            }}
            onClick={() => {
              copy(config);
              addMessage("Copied to Clipboard", "success", 3);
            }}
          >
            <ContentCopyIcon fontSize="small" />
            Copy
          </Button>
        </Box>
        <pre style={{ margin: 0, whiteSpace: "pre-wrap" }}>
          <code>{config}</code>
        </pre>
      </Box>
    </Box>
  );
};
