import Prism from "prismjs";
import "prismjs/components/prism-json";
import Editor from "react-simple-code-editor";
import "./prism.css";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import {
  MethodType,
  ToolCreate2,
  useToolsGetToolsGetQuery,
} from "../../../state/layerApi";

interface GeneralTabProps {
  values: ToolCreate2;
  setValues: React.Dispatch<React.SetStateAction<ToolCreate2>>;
  errors: Partial<Record<keyof ToolCreate2, string>>;
  validateValues: () => void;
}

const GeneralTab = ({
  values,
  setValues,
  errors,
  validateValues,
}: GeneralTabProps) => {
  function toggleStrict() {
    setValues((prevValues: ToolCreate2) => {
      return { ...prevValues, strict: !prevValues.strict };
    });
  }

  return (
    <>
      <TextField
        label="Name"
        variant="filled"
        required
        value={values.name}
        onChange={(e) => {
          if (errors.name) {
            validateValues();
          }
          setValues((prevValues: ToolCreate2) => {
            return { ...prevValues, name: e.target.value };
          });
        }}
        error={errors.name ? true : false}
        helperText={
          errors.name
            ? errors.name
            : "Name must be unique and only contain alphanumeric characters, - or _"
        }
      />
      <TextField
        label="Description"
        variant="filled"
        required
        multiline
        value={values.description}
        onChange={(e) => {
          if (errors.description) {
            validateValues();
          }
          setValues((prevValues: ToolCreate2) => {
            return { ...prevValues, description: e.target.value };
          });
        }}
        error={errors.description ? true : false}
        helperText={errors.description || ""}
      />
      <TextField
        label="URL"
        variant="filled"
        required
        value={values.url}
        onChange={(e) => {
          if (errors.url) {
            validateValues();
          }
          setValues((prevValues: ToolCreate2) => {
            return { ...prevValues, url: e.target.value };
          });
        }}
        error={errors.url ? true : false}
        helperText={errors.url || ""}
      />
      <FormControl
        variant="filled"
        error={errors.method ? true : false}
        required
      >
        <InputLabel id="method-label">Method</InputLabel>
        <Select
          labelId="method-label"
          variant="filled"
          required
          value={values.method}
          onChange={(e) => {
            if (errors.method) {
              validateValues();
            }
            setValues((prevValues: ToolCreate2) => {
              return { ...prevValues, method: e.target.value as MethodType };
            });
          }}
        >
          <MenuItem value="GET">GET</MenuItem>
          <MenuItem value="POST">POST</MenuItem>
          <MenuItem value="PUT">PUT</MenuItem>
          <MenuItem value="PATCH">PATCH</MenuItem>
          <MenuItem value="DELETE">DELETE</MenuItem>
          <MenuItem value="OPTIONS">OPTIONS</MenuItem>
          <MenuItem value="HEAD">HEAD</MenuItem>
          <MenuItem value="TRACE">TRACE</MenuItem>
        </Select>
        {errors.method && <FormHelperText>{errors.method}</FormHelperText>}
      </FormControl>
      <Box
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={toggleStrict}
      >
        <Checkbox checked={values.strict ?? true} />
        <Typography>Strict Schema</Typography>
      </Box>
    </>
  );
};

interface ParameterTabProps extends GeneralTabProps {
  tab: "auth" | "params" | "body" | "defs";
  parameterStrings: ParameterStrings;
  setParameterStrings: React.Dispatch<React.SetStateAction<ParameterStrings>>;
}

interface ParameterStrings {
  auth: string;
  params: string;
  body: string;
  defs: string;
}

const ParameterTab = ({
  parameterStrings,
  setParameterStrings,
  tab,
  errors,
  validateValues,
}: ParameterTabProps) => {
  const theme = useTheme();
  const error = errors[tab];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
      <Editor
        value={parameterStrings[tab]}
        onValueChange={(code: string) => {
          if (errors[tab]) {
            validateValues();
          }
          setParameterStrings((prevStrings) => {
            return { ...prevStrings, [tab]: code };
          });
        }}
        highlight={(code: string) =>
          Prism.highlight(code, Prism.languages.json, "json")
        }
        padding={10}
        tabSize={4}
        style={{
          borderRadius: "4px",
          border: `1px solid ${error ? "#D3302F" : theme.palette.divider}`,
        }}
      />
      <Typography variant="caption" color="error">
        {error}
      </Typography>
    </Box>
  );
};

interface AuthTabProps {
  values: ToolCreate2;
  setValues: React.Dispatch<React.SetStateAction<ToolCreate2>>;
  errors: Partial<Record<keyof ToolCreate2, string>>;
  validateValues: () => void;
}

const AuthTab = ({
  values,
  setValues,
  errors,
  validateValues,
}: AuthTabProps) => {
  return <></>;
};

interface ToolFormProps {
  initialValues?: ToolCreate2;
  onSubmit: (values: ToolCreate2) => Promise<void>;
  submitButtonLabel: string;
  toolId?: number;
}

const ToolForm = ({
  initialValues,
  onSubmit,
  submitButtonLabel,
  toolId,
}: ToolFormProps) => {
  const { data: tools } = useToolsGetToolsGetQuery();

  const [activeTab, setActiveTab] = useState<
    "general" | "auth" | "params" | "body" | "defs"
  >("general");

  const [values, setValues] = useState<ToolCreate2>(
    initialValues || {
      name: "",
      description: "",
      url: "",
      method: "GET",
      auth: null,
      params: null,
      body: null,
      defs: null,
      strict: true,
    }
  );

  const [parameterStrings, setParameterStrings] = useState<ParameterStrings>({
    auth: values.auth ? JSON.stringify(values.auth, null, 2) : "",
    params: values.params ? JSON.stringify(values.params, null, 2) : "",
    body: values.body ? JSON.stringify(values.body, null, 2) : "",
    defs: values.defs ? JSON.stringify(values.defs, null, 2) : "",
  });

  const [errors, setErrors] = useState<
    Partial<Record<keyof ToolCreate2, string>>
  >({});

  function validateValues() {
    const newErrors: Partial<Record<keyof ToolCreate2, string>> = {};
    const requiredFields = ["name", "description", "url", "method"];
    requiredFields.forEach((field) => {
      if (
        values[field as keyof ToolCreate2] === "" ||
        !values[field as keyof ToolCreate2]
      ) {
        newErrors[field as keyof ToolCreate2] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
    });

    // const parameterFields = ["auth", "params", "body", "defs"];
    // parameterFields.forEach((field) => {
    //   try {
    //     JSON.parse(parameterStrings[field as keyof ParameterStrings]);
    //     // TODO: Actual Schema Validation (Bring back schema schema)
    //   } catch {
    //     newErrors[field as keyof ToolCreate2] = `Invalid JSON Schema.`;
    //   }
    // });

    try {
      new URL(values.url);
    } catch {
      newErrors.url = "Invalid URL.";
    }

    if (
      tools?.some((tool) => tool.name === values.name && tool.id !== toolId)
    ) {
      newErrors.name = "Name must be unique.";
    }

    if (!values.name.match(/^[a-zA-Z0-9_-]+$/)) {
      newErrors.name = "Name must only contain alphanumeric characters, - or _";
    }

    setErrors(newErrors);
    return newErrors;
  }

  function navigateToErrorTab(
    errors: Partial<Record<keyof ToolCreate2, string>>
  ) {
    const errorTabs = Object.keys(errors).reduce<string[]>(
      (errorTabs: string[], errorKey) => {
        if (errorTabs.includes(errorKey)) {
          return errorTabs;
        }
        switch (errorKey) {
          case "auth":
            errorTabs.push("auth");
            break;
          case "params":
            errorTabs.push("params");
            break;
          case "body":
            errorTabs.push("body");
            break;
          case "defs":
            errorTabs.push("defs");
            break;
          default:
            errorTabs.push("general");
            break;
        }
        return errorTabs;
      },
      []
    );

    if (!errorTabs.includes(activeTab) && errorTabs.length > 0) {
      setActiveTab(
        errorTabs[0] as "auth" | "params" | "body" | "general" | "defs"
      );
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxHeight: "100%",
        overflow: "hidden",
      }}
    >
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tab label="General" value="general" />
        <Tab label="Auth" value="auth" />
        <Tab label="Params" value="params" />
        <Tab label="Body" value="body" />
        <Tab label="Defs" value="defs" />
      </Tabs>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          maxHeight: "100%",
          overflowY: "scroll",
        }}
      >
        {activeTab === "general" ? (
          <GeneralTab
            values={values}
            setValues={setValues}
            errors={errors}
            validateValues={validateValues}
          />
        ) : (
          <ParameterTab
            values={values}
            setValues={setValues}
            tab={activeTab}
            errors={errors}
            validateValues={validateValues}
            parameterStrings={parameterStrings}
            setParameterStrings={setParameterStrings}
          />
        )}
      </Box>
      <Button
        variant="contained"
        onClick={() => {
          const errors = validateValues();
          if (Object.keys(errors).length > 0) {
            navigateToErrorTab(errors);
            return;
          }

          const updatedValues = {
            ...values,
            auth: parameterStrings.auth
              ? JSON.parse(parameterStrings.auth)
              : null,
            params: parameterStrings.params
              ? JSON.parse(parameterStrings.params)
              : null,
            body: parameterStrings.body
              ? JSON.parse(parameterStrings.body)
              : null,
            defs: parameterStrings.defs
              ? JSON.parse(parameterStrings.defs)
              : null,
          };

          onSubmit(updatedValues);
        }}
      >
        {submitButtonLabel}
      </Button>
    </Box>
  );
};

export default ToolForm;
