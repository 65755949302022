import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import {
  Extension,
  useExtensionsGetExtensionsGetQuery,
  useExtensionsUpdateExtensionPutMutation,
  useLazyExtensionsPublishExtensionGetQuery,
} from "../../../state/layerApi";
import { Edit, Publish, Save } from "@mui/icons-material";
import { useDownloadExtensionObjectQuery } from "../../../state/emptyApi";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { LoadingButton } from "@mui/lab";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import ModalDialog from "../../../shared/ModalDialog";
import Form, { Field } from "../../../shared/Form";

const VSCodeBaseField = ({
  fieldName,
  fieldLabel,
  multiline = false,
}: {
  fieldName: keyof Extension;
  fieldLabel: string;
  multiline?: boolean;
}) => {
  const { addMessage } = useSnackbarContext();
  const { data: extensions, isLoading } = useExtensionsGetExtensionsGetQuery();

  const [value, setValue] = React.useState<string>("");
  const [editing, setEditing] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const [updateExtension, { isLoading: isSubmitLoading }] =
    useExtensionsUpdateExtensionPutMutation();

  React.useEffect(() => {
    if (
      extensions &&
      fieldName in extensions?.[0] &&
      extensions?.[0]?.[fieldName]
    ) {
      setValue(extensions[0][fieldName] as string);
    }
  }, [extensions]);

  if (isLoading) {
    return <CircularProgress />;
  }

  function handleUpdateField() {
    if (!value) {
      setError("Field cannot be empty");
      return;
    } else if (error) {
      setError(null);
    }
    const formData = new FormData();
    formData.append(fieldName, value);
    updateExtension({
      extensionId: extensions?.[0].id || 0,
      // @ts-ignore
      extensionUpdate: formData,
    })
      .unwrap()
      .then(() => setEditing(false))
      .catch(() => addMessage(`Failed to update ${fieldLabel}`, "error"));
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, width: "100%" }}>
      <TextField
        label={fieldLabel}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setError(null);
        }}
        sx={{ flexGrow: 1 }}
        variant="filled"
        disabled={!editing}
        multiline={multiline}
        error={Boolean(error)}
        helperText={error}
      />
      {editing ? (
        isSubmitLoading ? (
          <CircularProgress />
        ) : (
          <IconButton onClick={handleUpdateField}>
            <Save />
          </IconButton>
        )
      ) : (
        <IconButton onClick={() => setEditing(!editing)}>
          <Edit />
        </IconButton>
      )}
    </Box>
  );
};

export const VSCodeDisplayNameField = () => {
  return <VSCodeBaseField fieldName="display_name" fieldLabel="Display Name" />;
};

export const VSCodeDescriptionField = () => {
  return (
    <VSCodeBaseField
      fieldName="description"
      fieldLabel="Description"
      multiline
    />
  );
};

export const VSCodeParticipantNameField = () => {
  return (
    <VSCodeBaseField
      fieldName="participant_name"
      fieldLabel="Participant Name"
    />
  );
};

export const VSCodeParticipantDescriptionField = () => {
  return (
    <VSCodeBaseField
      fieldName="participant_description"
      fieldLabel="Participant Description"
    />
  );
};

export const VSCodeLogoField = () => {
  const { addMessage } = useSnackbarContext();
  const { data: extensions, isLoading } = useExtensionsGetExtensionsGetQuery();
  const { data: initialLogoData, isLoading: logoLoading } =
    useDownloadExtensionObjectQuery(
      {
        extensionId: extensions?.[0]?.id || 0,
        specialObjectName: "logo.png",
      },
      {
        skip: extensions?.[0]?.id === undefined,
      }
    );

  const [logoData, setLogoData] = React.useState<string>("");
  const [editing, setEditing] = React.useState<boolean>(false);
  const [updateExtension, { isLoading: isSubmitLoading }] =
    useExtensionsUpdateExtensionPutMutation();

  React.useEffect(() => {
    if (initialLogoData) {
      setLogoData(initialLogoData);
    }
  }, [initialLogoData]);

  if (isLoading || logoLoading) {
    return <CircularProgress />;
  }

  const editLogoFields: Field[] = [
    {
      fieldName: "logoData",
      fieldLabel: "Extension Icon",
      fieldType: "file",
      fieldRequired: false,
      fieldAcceptedFileExtensions: [".png", ".jpg"],
      fieldImageMinSize: { width: 128, height: 128 },
      fieldImageCrop: {
        aspectRatio: 1,
        minSize: { width: 128, height: 128 },
        rescaleImageWidth: 256,
      },
    },
  ];

  function handleUpdateLogo({ logoData }: { logoData: string }) {
    const formData = new FormData();
    formData.append("logo_file", logoData);
    updateExtension({
      extensionId: extensions?.[0].id || 0,
      // @ts-ignore
      extensionUpdate: formData,
    })
      .unwrap()
      .then(() => setEditing(false))
      .catch(() => addMessage("Failed to update logo", "error"));
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          width: "100%",
        }}
      >
        <img src={logoData} alt="Logo" style={{ height: 100 }} />
        <IconButton onClick={() => setEditing(true)}>
          <Edit />
        </IconButton>
      </Box>
      <ModalDialog
        modalOpen={editing}
        handleClose={() => setEditing(false)}
        label="Update Logo"
      >
        <Form
          fields={editLogoFields}
          handleFormSubmit={(fields) =>
            handleUpdateLogo(fields as { logoData: string })
          }
          submitButtonLoading={isSubmitLoading}
          submitButtonLabel="Update Logo"
        />
      </ModalDialog>
    </>
  );
};

export const VSCodeReadmeField = () => {
  const { addMessage } = useSnackbarContext();
  const { data: extensions, isLoading } = useExtensionsGetExtensionsGetQuery();
  const { data: initialReadmeData, isLoading: readmeLoading } =
    useDownloadExtensionObjectQuery(
      {
        extensionId: extensions?.[0]?.id || 0,
        specialObjectName: "README.md",
      },
      {
        skip: extensions?.[0]?.id === undefined,
      }
    );

  const [readmeData, setReadmeData] = React.useState<string>("");
  const [editing, setEditing] = React.useState<boolean>(false);
  const [updateExtension, { isLoading: isSubmitLoading }] =
    useExtensionsUpdateExtensionPutMutation();

  React.useEffect(() => {
    if (initialReadmeData) {
      setReadmeData(initialReadmeData);
    }
  }, [initialReadmeData]);

  if (isLoading || readmeLoading) {
    return <CircularProgress />;
  }

  function handleUpdateReadme() {
    const formData = new FormData();
    const readmeFile = new File(
      [new Blob([readmeData], { type: "text/markdown" })],
      "readme.md"
    );
    formData.append("readme_file", readmeFile);
    updateExtension({
      extensionId: extensions?.[0].id || 0,
      // @ts-ignore
      extensionUpdate: formData,
    })
      .unwrap()
      .then(() => setEditing(false))
      .catch(() => addMessage("Failed to update readme", "error"));
  }

  return (
    <Box
      sx={{ display: "flex", alignItems: "flex-start", gap: 2, width: "100%" }}
    >
      <MDEditor
        value={readmeData}
        onChange={(value) => setReadmeData(value || "")}
        style={{ flexGrow: 1 }}
        height={500}
        preview={editing ? "live" : "preview"}
        highlightEnable={false}
        previewOptions={{
          rehypePlugins: [[rehypeSanitize]],
        }}
      />
      {editing ? (
        isSubmitLoading ? (
          <CircularProgress />
        ) : (
          <IconButton onClick={handleUpdateReadme}>
            <Save />
          </IconButton>
        )
      ) : (
        <IconButton onClick={() => setEditing(!editing)}>
          <Edit />
        </IconButton>
      )}
    </Box>
  );
};

export const VSCodePublishButton = () => {
  const { addMessage } = useSnackbarContext();
  const { data: extensions, isLoading } = useExtensionsGetExtensionsGetQuery();

  const [publishExtension, { isLoading: publishLoading }] =
    useLazyExtensionsPublishExtensionGetQuery();

  if (isLoading) {
    return <CircularProgress />;
  }

  function handlePublish() {
    publishExtension({
      extensionId: extensions?.[0].id || 0,
    })
      .unwrap()
      .then(() => addMessage("Extension published!", "success"))
      .catch(() => addMessage("Failed to publish extension", "error"));
  }

  const readyToPublish = extensions?.[0]?.pending_publish;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip
        title="Cannot publish extension until changes are made"
        disableFocusListener={readyToPublish}
        disableHoverListener={readyToPublish}
        disableTouchListener={readyToPublish}
      >
        <span>
          <LoadingButton
            variant="contained"
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              height: "fit-content",
            }}
            onClick={handlePublish}
            loading={publishLoading}
            disabled={!readyToPublish}
          >
            <Publish />
            Publish Extension
          </LoadingButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export const VSCodeMarketplaceLink = () => {
  const { data: extensions, isLoading } = useExtensionsGetExtensionsGetQuery();
  const [marketplaceLink, setMarketplaceLink] = React.useState<string>("");
  const [marketplaceAvailable, setMarketplaceAvailable] =
    React.useState<boolean>(false);
  const [marketplaceLoading, setMarketplaceLoading] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (extensions && extensions[0].marketplace_url) {
      setMarketplaceLink(extensions[0].marketplace_url);
    }
  }, [extensions]);

  React.useEffect(() => {
    if (!marketplaceLink) return;
    setMarketplaceLoading(true);
    try {
      fetch(marketplaceLink).then((response) => {
        if (!response.ok) {
          setMarketplaceAvailable(false);
        } else {
          setMarketplaceAvailable(true);
        }
      });
    } catch {
      setMarketplaceAvailable(false);
    } finally {
      setMarketplaceLoading(false);
    }
  }, [marketplaceLink]);

  if (isLoading || marketplaceLoading) {
    return <CircularProgress />;
  }

  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        border: "1px solid",
        borderRadius: 1,
        borderColor: "divider",
        height: "fit-content",
      }}
    >
      {marketplaceAvailable ? (
        <a href={marketplaceLink} target="_blank" rel="noreferrer">
          {marketplaceLink}
        </a>
      ) : (
        <p>Not published to marketplace</p>
      )}
    </Box>
  );
};
