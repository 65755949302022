import { useMemo } from "react";
import { AnalyticsSectionProps, hashIp } from "./Analytics";
import {
  MessageLog,
  useChatGetMessageLogsGetQuery,
} from "../../../state/layerApi";
import { GridColDef } from "@mui/x-data-grid";
import { formatDateTime } from "../../../utils/dateTime";
import { Box, CircularProgress } from "@mui/material";
import AnalyticsDataGrid from "./AnalyticsDataGrid";
import AnalyticsBarChart from "./AnalyticsBarChart";
import AnalyticsCard from "./AnalyticsCard";
import { faker } from "@faker-js/faker";

const QueriesAnalytics = ({
  startDate,
  endDate,
  dummyData = false,
}: AnalyticsSectionProps) => {
  const { data: actualQueries, isLoading } = useChatGetMessageLogsGetQuery();

  const fakeQueries: MessageLog[] = [];

  for (let i = 0; i < Math.floor(Math.random() * 500 + 5000); i++) {
    fakeQueries.push({
      id: i,
      ip: faker.internet.ip(),
      message: faker.lorem.sentence(),
      created_at: faker.date.recent({ days: 180 }).toISOString().slice(0, -1),
      customer_id: 1,
    });
  }

  const queries = dummyData ? fakeQueries : actualQueries;

  const filteredQueries = useMemo(() => {
    if (!queries) {
      return [];
    }
    return queries.filter((query) => {
      const queryDate = new Date(query.created_at + "Z");
      return queryDate >= startDate && queryDate <= endDate;
    });
  }, [queries, startDate, endDate]);

  const queryRows = useMemo(() => {
    return filteredQueries.map((query) => {
      return {
        id: query.id,
        userId: hashIp(query.ip),
        query: query.message,
        timestamp: new Date(query.created_at! + "Z"),
      };
    });
  }, [queries, startDate, endDate]);

  const queryChartData = useMemo(() => {
    let chartData: { data: { date: Date; value: number }[] }[] = [{ data: [] }];
    queryRows?.forEach((query) => {
      chartData[0].data.push({ date: query.timestamp, value: 1 });
    });
    return chartData;
  }, [queryRows, startDate, endDate]);

  const queriesTableColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 200,
      type: "number",
    },
    { field: "query", headerName: "Query", flex: 1, type: "string" },
    {
      field: "userId",
      headerName: "User ID",
      width: 150,
      type: "string",
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      width: 150,
      type: "dateTime",
      renderCell: (params) => {
        return formatDateTime(params.value);
      },
    },
  ];

  if (isLoading || !queries) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", gap: 2 }}>
        <AnalyticsCard label="Total Queries" data={filteredQueries.length} />
      </Box>
      <AnalyticsBarChart
        chartData={queryChartData}
        startDate={startDate}
        endDate={endDate}
      />
      <AnalyticsDataGrid
        columns={queriesTableColumns}
        rows={queryRows}
        tableName="Queries"
        initialStateOverrides={{
          sorting: {
            sortModel: [{ field: "timestamp", sort: "desc" }],
          },
        }}
        startDate={startDate}
        endDate={endDate}
      />
    </Box>
  );
};

export default QueriesAnalytics;
