import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { BarChart } from "@mui/x-charts";
import React, { useEffect, useMemo } from "react";
import { AnalyticsSectionProps } from "./Analytics";

interface IAnalyticsBarChart extends AnalyticsSectionProps {
  chartData: {
    data: { date: Date; value: number; duplicateId?: number }[];
    name?: string;
    type?: "bar";
  }[];
}

const AnalyticsBarChart = ({
  chartData,
  startDate,
  endDate,
}: IAnalyticsBarChart) => {
  const theme = useTheme();

  const diffInDays = Math.ceil(
    (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
  );

  const { xLabels, processedData } = useMemo(() => {
    const labels: string[] = [];

    if (diffInDays <= 1) {
      const hours = Array.from({ length: 24 }, (_, i) => {
        const date = new Date(startDate);
        date.setHours(i, 0, 0, 0);
        return date;
      });
      labels.push(
        ...hours.map((date) =>
          date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
        )
      );
    } else if (diffInDays <= 31) {
      for (let i = 0; i < diffInDays; i++) {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i);
        labels.push(
          date.toLocaleDateString([], { month: "short", day: "numeric" })
        );
      }
    } else {
      const months = [];
      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        months.push(new Date(currentDate));
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
      labels.push(
        ...months.map((date) =>
          date.toLocaleDateString([], { month: "short", year: "2-digit" })
        )
      );
    }

    const processed = chartData.map((series) => {
      const alignedData = new Array(labels.length).fill(0);
      const processedIds = new Set<string>();

      series.data.forEach((item) => {
        const itemDate = new Date(item.date);
        let index = 0;

        if (diffInDays <= 1) {
          index = itemDate.getHours();
        } else if (diffInDays <= 31) {
          index = Math.floor(
            (itemDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
          );
        } else {
          index =
            (itemDate.getFullYear() - startDate.getFullYear()) * 12 +
            (itemDate.getMonth() - startDate.getMonth());
        }

        const uniqueKey = item.duplicateId
          ? `${index}-${item.duplicateId}`
          : Math.random().toString();

        if (
          index >= 0 &&
          index < labels.length &&
          !processedIds.has(uniqueKey)
        ) {
          alignedData[index] += item.value;
          processedIds.add(uniqueKey);
        }
      });

      return {
        ...series,
        data: alignedData,
      };
    });

    return { xLabels: labels, processedData: processed };
  }, [chartData, startDate, endDate]);

  return (
    <Box
      sx={{
        borderRadius: 2,
        border: 1,
        borderColor: "divider",
        p: 2,
        display: "flex",
      }}
    >
      <BarChart
        series={processedData}
        height={290}
        colors={[theme.palette.primary.main]}
        xAxis={[
          {
            data: xLabels,
            scaleType: "band",
            tickLabelStyle: {
              angle: 45,
              textAnchor: "start",
              fontSize: 12,
            },
            tickLabelInterval: (_, index) => {
              if (diffInDays <= 1) {
                return index % 2 === 0;
              } else if (diffInDays <= 15) {
                return true;
              } else if (diffInDays <= 31) {
                return index % 2 === 0;
              } else {
                const interval = Math.max(1, Math.floor(xLabels.length / 12));
                return index % interval === 0;
              }
            },
          },
        ]}
        margin={{ top: 20, bottom: 60, left: 40, right: 10 }}
        grid={{ horizontal: true }}
      />
    </Box>
  );
};

export default AnalyticsBarChart;
