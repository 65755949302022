import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridOverlay,
} from "@mui/x-data-grid";
import AnalyticsGridToolbar from "./AnalyticsGridToolbar";
import { Typography } from "@mui/material";

const AnalyticsDataGrid = ({
  columns,
  rows,
  tableName,
  dataGridPropOverrides,
  initialStateOverrides,
  startDate,
  endDate,
}: {
  columns: GridColDef[];
  rows: any[];
  tableName: string;
  dataGridPropOverrides?: Omit<Partial<DataGridProps>, "columns" | "rows">;
  initialStateOverrides?: Partial<DataGridProps["initialState"]>;
  startDate: Date;
  endDate: Date;
}) => {
  return (
    <DataGrid
      columns={columns}
      rows={rows}
      sx={{ maxHeight: "375px" }}
      rowSelection={false}
      pageSizeOptions={[10, 25, 50, 100]}
      columnVisibilityModel={{
        id: false,
      }}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 10 },
        },
        ...initialStateOverrides,
      }}
      slots={{
        toolbar: () => (
          <AnalyticsGridToolbar
            tableName={tableName}
            rows={rows}
            headers={columns
              .map((column) => column.field)
              .filter((field) => field !== "id")}
            startDate={startDate}
            endDate={endDate}
          />
        ),
        noRowsOverlay: () => (
          <GridOverlay>
            <Typography variant="body2" color="GrayText">
              No Data Available
            </Typography>
          </GridOverlay>
        ),
        noResultsOverlay: () => (
          <GridOverlay>
            <Typography variant="body2" color="GrayText">
              No Results
            </Typography>
          </GridOverlay>
        ),
      }}
      {...dataGridPropOverrides}
    />
  );
};

export default AnalyticsDataGrid;
