import {
  Box,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ApiIcon from "@mui/icons-material/Api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import DeleteConfirmationModal from "../../../shared/DeleteConfirmationModal";
import AddToolModal from "./AddToolModal";
import {
  Tool2,
  useToolsDeleteToolDeleteMutation,
} from "../../../state/layerApi";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import AddAPISpecModal from "./AddAPISpecModal";
import Tools from "./Tools";

interface ToolsTableAddButtonProps {
  setAddModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAddApiSpecModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tools: Tool2[];
}

const ToolsTableAddButton = ({
  setAddApiSpecModalOpen,
  setAddModalOpen,
  tools,
}: ToolsTableAddButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget.parentElement);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Tooltip
      title="Can only add up to 15 tools"
      disableInteractive
      arrow
      disableHoverListener={true}
      disableFocusListener={true}
      disableTouchListener={true}
      slotProps={{
        tooltip: { sx: { backgroundColor: "black" } },
        arrow: { sx: { color: "black" } },
      }}
    >
      <span>
        <Button
          // disabled={tools.length >= 15}
          variant="contained"
          onClick={() => setAddApiSpecModalOpen(true)}
          sx={{
            minWidth: 0,
            height: "48px",
            display: "flex",
            alignItems: "center",
            gap: 1,
            pr: 0,
          }}
        >
          <AddIcon />
          <Typography variant="button">Add from OpenAPI Spec</Typography>
          <Box
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
            sx={{
              minWidth: 0,
              width: "24px",
              px: 3,
              py: 1,
              borderLeft: 1,
              borderColor: "divider",
              borderRadius: 0,
              ml: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ExpandMoreIcon
              sx={{
                color: "white",
                transition: "transform 0.3s ease-in-out",
                transform: `rotate(${open ? "180deg" : "0deg"})`,
              }}
            />
          </Box>
        </Button>
        <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setAddModalOpen(true);
              setAnchorEl(null);
            }}
          >
            <Typography variant="button">Add Manually</Typography>
          </MenuItem>
        </Menu>
      </span>
    </Tooltip>
  );
};

interface ToolsTableToolbarProps {
  selectedRowIds: GridRowSelectionModel;
  inApiSpecModal?: boolean;
  tools: Tool2[];
}

const ToolsTableToolbar = ({
  selectedRowIds,
  inApiSpecModal = false,
  tools,
}: ToolsTableToolbarProps) => {
  const { addMessage } = useSnackbarContext();

  const [deleteTool, { isLoading }] = useToolsDeleteToolDeleteMutation();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [addApiSpecModalOpen, setAddApiSpecModalOpen] =
    useState<boolean>(false);

  function handleDeleteTools() {
    Promise.all(
      selectedRowIds.map((id) => deleteTool({ toolId: id as number }))
    )
      .then(() => {
        setDeleteModalOpen(false);
        addMessage("Tools deleted", "success");
      })
      .catch((err) => {
        console.error(err);
        setDeleteModalOpen(false);
        addMessage("Failed to delete tools", "error");
      });
  }

  return (
    <>
      <GridToolbarContainer>
        <Box
          sx={{
            display: "flex",
            gap: 4,
            alignItems: "center",
            p: "12px",
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          <GridToolbarQuickFilter sx={{ flexGrow: 1 }} variant="filled" />
          {!inApiSpecModal && (
            <Box
              sx={{
                display: "flex",
                gap: "12px",
                height: "48px",
              }}
            >
              <Button
                variant="outlined"
                disabled={selectedRowIds.length === 0 || isLoading}
                color="error"
                onClick={() => setDeleteModalOpen(true)}
                sx={{ minWidth: 0, width: "56px", height: "48px" }}
              >
                <DeleteIcon />
              </Button>
              <ToolsTableAddButton
                setAddModalOpen={setAddModalOpen}
                setAddApiSpecModalOpen={setAddApiSpecModalOpen}
                tools={tools}
              />
            </Box>
          )}
        </Box>
      </GridToolbarContainer>
      <DeleteConfirmationModal
        modalOpen={deleteModalOpen}
        itemName={
          selectedRowIds.length > 1
            ? selectedRowIds.length + " Tools"
            : "1 Tool"
        }
        setOpen={setDeleteModalOpen}
        handleDelete={handleDeleteTools}
      />
      <AddAPISpecModal
        modalOpen={addApiSpecModalOpen}
        handleClose={() => setAddApiSpecModalOpen(false)}
      />
      <AddToolModal
        modalOpen={addModalOpen}
        handleClose={() => setAddModalOpen(false)}
      />
    </>
  );
};

export default ToolsTableToolbar;
