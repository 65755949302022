import ModalDialog from "../../../shared/ModalDialog";
import {
  Prompt2,
  PromptUpdate,
  usePromptsUpdatePromptPatchMutation,
} from "../../../state/layerApi";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import PromptForm from "./PromptForm/PromptForm";

interface EditPromptModalProps {
  prompt?: Prompt2;
  modalOpen: boolean;
  handleClose: () => void;
}

const EditPromptModal = ({
  prompt,
  modalOpen,
  handleClose,
}: EditPromptModalProps) => {
  const { addMessage } = useSnackbarContext();
  const [editPrompt, { isLoading }] = usePromptsUpdatePromptPatchMutation();

  function handleEditPrompt(newPrompt: PromptUpdate) {
    editPrompt({
      promptUpdate: newPrompt,
      promptId: prompt?.id || 0,
    }).then(() => {
      handleClose();
      addMessage("Prompt updated", "success");
    });
  }

  return (
    <ModalDialog
      label="Edit Prompt"
      modalOpen={modalOpen}
      handleClose={handleClose}
      defaultWidth={800}
      styleOverrides={{ gap: 0 }}
    >
      <PromptForm
        handleSubmit={handleEditPrompt}
        isLoading={isLoading}
        initialPrompt={prompt}
        submitButtonLabel="Save Changes"
      />
    </ModalDialog>
  );
};

export default EditPromptModal;
