import React, { useState } from "react";
import ModalDialog from "../../../shared/ModalDialog";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { Tool2 } from "../../../state/layerApi";
import AddAPISpecModalContent from "./AddAPISpecModalContent";

interface AddAPISpecModalProps {
  modalOpen: boolean;
  handleClose: () => void;
}

interface AddAPISpecValues {
  uploadType: "file" | "url";
  url?: string;
  file?: File;
}

const AddAPISpecModal = ({ modalOpen, handleClose }: AddAPISpecModalProps) => {
  const [converted, setConverted] = React.useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>(
    []
  );

  return (
    <ModalDialog
      label={converted ? `Select Endpoints to Add as Tools` : "Add API Spec"}
      modalOpen={modalOpen}
      handleClose={() => {
        handleClose();
        setSelectedRowIds([]);
        setConverted(false);
      }}
      defaultWidth={800}
    >
      <AddAPISpecModalContent
        handleClose={handleClose}
        converted={converted}
        setConverted={setConverted}
        selectedRowIds={selectedRowIds}
        setSelectedRowIds={setSelectedRowIds}
      />
    </ModalDialog>
  );
};

export default AddAPISpecModal;
