import { formatDateTime } from "../../../utils/dateTime";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { PromptArgument, PromptStep } from "../../../state/layerApi";

export function PromptsTableColumns(
  onEditClick: (id: number) => void,
  onDeleteClick: (ids: number[]) => void
): GridColDef[] {
  return [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      sortable: false,
      hideable: false,
    },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: true,
      flex: 2,
    },
    {
      field: "created_at",
      headerName: "Created On",
      type: "dateTime",
      valueGetter: (value) => value && new Date(value + "Z"),
      valueFormatter: (value?: Date) => {
        if (value == null) {
          return "";
        }
        return formatDateTime(value);
      },
      sortable: true,
    },
    {
      field: "updated_at",
      headerName: "Updated On",
      type: "dateTime",
      valueGetter: (value) => value && new Date(value + "Z"),
      valueFormatter: (value?: Date) => {
        if (value == null) {
          return "";
        }
        return formatDateTime(value);
      },
      sortable: true,
      flex: 1,
    },
    {
      field: "arguments",
      headerName: "Arguments",
      valueGetter: (_, row) => {
        return row.arguments
          .map((arg: PromptArgument) => {
            return arg.name;
          })
          .join(", ");
      },
      sortable: true,
      flex: 1,
    },
    {
      field: "steps",
      headerName: "Steps",
      valueGetter: (_, row) => {
        return row.steps
          .map((step: PromptStep, idx: number) => {
            return idx + 1 + ". " + step.instruction;
          })
          .join("\n");
      },
      sortable: true,
      flex: 2,
    },
    {
      field: "actions",
      headerName: "",
      type: "actions",
      sortable: false,
      width: 40,
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          icon={<EditIcon />}
          onClick={() => onEditClick(params.row.id)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          icon={<DeleteIcon />}
          onClick={() => onDeleteClick([params.row.id])}
          showInMenu
        />,
      ],
    },
  ];
}
