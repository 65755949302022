import { ExpandMore } from "@mui/icons-material";
import { Box, Button, Menu, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useMemo, useState } from "react";
import dayjs from "dayjs";
import {
  getEndOfDay,
  getStartOfDay,
  shiftDateBackDays,
  shiftDateBackMonths,
} from "../utils/dateTime";

interface DateRangePickerMenuProps {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
}

const DateRangePickerMenu = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: DateRangePickerMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dateLabel = useMemo(() => {
    const today = new Date();
    const isSameDay = (d1: Date, d2: Date) =>
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();

    if (isSameDay(startDate, endDate)) {
      if (isSameDay(startDate, today)) {
        return "Today";
      }
      return startDate.toLocaleDateString();
    }

    const last7Days = shiftDateBackDays(today, 6);
    const last30Days = shiftDateBackDays(today, 29);
    const last6Months = shiftDateBackMonths(today, 5);
    const last12Months = shiftDateBackMonths(today, 11);

    if (isSameDay(startDate, last7Days) && isSameDay(endDate, new Date())) {
      return "Last 7 Days";
    } else if (
      isSameDay(startDate, last30Days) &&
      isSameDay(endDate, new Date())
    ) {
      return "Last 30 Days";
    } else if (
      isSameDay(startDate, last6Months) &&
      isSameDay(endDate, new Date())
    ) {
      return "Last 6 Months";
    } else if (
      isSameDay(startDate, last12Months) &&
      isSameDay(endDate, new Date())
    ) {
      return "Last 12 Months";
    }

    return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
  }, [startDate, endDate]);

  function handleLabelClick(label: string) {
    switch (label) {
      case "Today":
        setStartDate(getStartOfDay(new Date()));
        setEndDate(getEndOfDay(new Date()));
        break;
      case "Last 7 Days":
        setStartDate(getStartOfDay(shiftDateBackDays(new Date(), 6)));
        setEndDate(getEndOfDay(new Date()));
        break;
      case "Last 30 Days":
        setStartDate(getStartOfDay(shiftDateBackDays(new Date(), 29)));
        setEndDate(getEndOfDay(new Date()));
        break;
      case "Last 6 Months":
        setStartDate(getStartOfDay(shiftDateBackMonths(new Date(), 5)));
        setEndDate(getEndOfDay(new Date()));
        break;
      case "Last 12 Months":
        setStartDate(getStartOfDay(shiftDateBackMonths(new Date(), 11)));
        setEndDate(getEndOfDay(new Date()));
        break;
    }
  }

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          border: "1px solid",
          borderColor: "divider",
          borderRadius: 1,
          py: 1,
          px: 2,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Typography>{dateLabel}</Typography>
        <ExpandMore
          sx={{
            transform: open ? "rotate(-180deg)" : "",
            transition: "0.3s ease",
          }}
        />
      </Box>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Box
          sx={{
            px: 2,
            py: 1,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <DatePicker
              label="Start Date"
              value={dayjs(startDate)}
              onChange={(date) => {
                if (!date) return;
                setStartDate(getStartOfDay(date.toDate()));
              }}
              maxDate={dayjs(new Date())}
              sx={{ flexGrow: 1 }}
            />
            <DatePicker
              label="End Date"
              value={dayjs(endDate)}
              onChange={(date) => {
                if (!date) return;
                setEndDate(date?.toDate());
                if (date?.toDate() < startDate) {
                  setStartDate(getEndOfDay(date.toDate()));
                }
              }}
              maxDate={dayjs(new Date())}
              sx={{ flexGrow: 1 }}
            />
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button size="small" onClick={() => handleLabelClick("Today")}>
              Today
            </Button>
            <Button
              size="small"
              onClick={() => handleLabelClick("Last 7 Days")}
            >
              Last 7 Days
            </Button>
            <Button
              size="small"
              onClick={() => handleLabelClick("Last 30 Days")}
            >
              Last 30 Days
            </Button>
            <Button
              size="small"
              onClick={() => handleLabelClick("Last 6 Months")}
            >
              Last 6 Months
            </Button>
            <Button
              size="small"
              onClick={() => handleLabelClick("Last 12 Months")}
            >
              Last 12 Months
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default DateRangePickerMenu;
