import { useMemo } from "react";
import { AnalyticsSectionProps, hashIp } from "./Analytics";
import {
  MessageLog,
  ToolLog,
  useChatGetMessageLogsGetQuery,
  useToolLogsGetToolLogsGetQuery,
} from "../../../state/layerApi";
import { GridColDef } from "@mui/x-data-grid";
import { formatDateTime } from "../../../utils/dateTime";
import { Box, CircularProgress } from "@mui/material";
import AnalyticsDataGrid from "./AnalyticsDataGrid";
import AnalyticsBarChart from "./AnalyticsBarChart";
import AnalyticsCard from "./AnalyticsCard";
import { faker } from "@faker-js/faker";

const UsersAnalytics = ({
  startDate,
  endDate,
  dummyData = false,
}: AnalyticsSectionProps) => {
  const { data: actualQueries, isLoading: queriesLoading } =
    useChatGetMessageLogsGetQuery();
  const { data: actualToolLogs, isLoading: toolLogsLoading } =
    useToolLogsGetToolLogsGetQuery();

  const fakeQueries: MessageLog[] = [];
  const fakeToolLogs: ToolLog[] = [];

  for (let j = 0; j < Math.floor(Math.random() * 50 + 1000); j++) {
    const ip = faker.internet.ip();
    for (let i = 0; i < Math.floor(Math.random() * 50 + 1); i++) {
      fakeQueries.push({
        id: i,
        ip: ip,
        message: faker.lorem.sentence(),
        created_at: faker.date.recent({ days: 180 }).toISOString().slice(0, -1),
        customer_id: 1,
      });
      if (Math.random() > 0.5) {
        fakeToolLogs.push({
          id: i,
          ip: ip,
          tool_id: Math.floor(Math.random() * 10),
          failure_message: faker.lorem.sentence(),
          client: faker.internet.userAgent(),
          response_status_code: Math.floor(Math.random() * 500),
          created_at: faker.date
            .recent({ days: 180 })
            .toISOString()
            .slice(0, -1),
          customer_id: 1,
        });
      }
    }
  }

  const queries = dummyData ? fakeQueries : actualQueries;
  const toolLogs = dummyData ? fakeToolLogs : actualToolLogs;

  const filteredQueries = useMemo(() => {
    if (!queries) {
      return [];
    }
    return queries.filter((query) => {
      const queryDate = new Date(query.created_at + "Z");
      return queryDate >= startDate && queryDate <= endDate;
    });
  }, [queries, startDate, endDate]);

  const filteredToolLogs = useMemo(() => {
    if (!toolLogs) {
      return [];
    }
    return toolLogs.filter((tool) => {
      const toolLogDate = new Date(tool.created_at + "Z");
      return toolLogDate >= startDate && toolLogDate <= endDate;
    });
  }, [toolLogs, startDate, endDate]);

  const userRows = useMemo(() => {
    const combinedLogs = [];
    if (filteredQueries) {
      combinedLogs.push(...filteredQueries);
    }
    if (filteredToolLogs) {
      combinedLogs.push(...filteredToolLogs);
    }
    const userCounts = combinedLogs.reduce(
      (
        acc: Record<
          string,
          { queryCount: number; toolCallCount: number; lastActive: Date }
        >,
        log: MessageLog | ToolLog
      ) => {
        if (log.ip in acc) {
          if ("message" in log) {
            acc[log.ip].queryCount += 1;
          } else {
            acc[log.ip].toolCallCount += 1;
          }
          if (new Date(log.created_at!) > acc[log.ip].lastActive) {
            acc[log.ip].lastActive = new Date(log.created_at! + "Z");
          }
        } else {
          if ("message" in log) {
            acc[log.ip] = {
              queryCount: 1,
              toolCallCount: 0,
              lastActive: new Date(log.created_at!),
            };
          } else {
            acc[log.ip] = {
              queryCount: 0,
              toolCallCount: 1,
              lastActive: new Date(log.created_at!),
            };
          }
        }
        return acc;
      },
      {}
    );

    if (!userCounts) return [];

    return Object.entries(userCounts).map(([ip, data], index) => ({
      userId: hashIp(ip),
      queryCount: data.queryCount,
      toolCallCount: data.toolCallCount,
      lastActive: data.lastActive,
      id: index + 1,
    }));
  }, [queries, toolLogs]);

  const userChartData = useMemo(() => {
    let chartData: {
      data: { date: Date; value: number; duplicateId: number }[];
    }[] = [{ data: [] }];
    const combinedLogs = [];
    if (filteredQueries) {
      combinedLogs.push(...filteredQueries);
    }
    if (filteredToolLogs) {
      combinedLogs.push(...filteredToolLogs);
    }
    combinedLogs?.forEach((log) => {
      chartData[0].data.push({
        date: new Date(log.created_at! + "Z"),
        value: 1,
        duplicateId: parseInt(log.ip),
      });
    });
    return chartData;
  }, [filteredQueries, filteredToolLogs]);

  const usersTableColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 200,
      type: "number",
    },
    {
      field: "userId",
      headerName: "User ID",
      flex: 1,
      type: "string",
    },
    {
      field: "queryCount",
      headerName: "Messages Sent",
      width: 150,
      type: "number",
    },
    {
      field: "toolCallCount",
      headerName: "Tool Calls",
      width: 150,
      type: "number",
    },
    {
      field: "lastActive",
      headerName: "Last Active",
      width: 150,
      type: "dateTime",
      renderCell: (params) => {
        if (!params.value) return "";
        return formatDateTime(params.value);
      },
    },
  ];

  if (queriesLoading || toolLogsLoading || !queries || !toolLogs) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", gap: 2 }}>
        <AnalyticsCard label="Total Unique Users" data={userRows.length} />
      </Box>
      <AnalyticsBarChart
        chartData={userChartData}
        startDate={startDate}
        endDate={endDate}
      />
      <AnalyticsDataGrid
        columns={usersTableColumns}
        rows={userRows}
        tableName="UniqueUsers"
        initialStateOverrides={{
          sorting: {
            sortModel: [{ field: "lastActive", sort: "desc" }],
          },
        }}
        startDate={startDate}
        endDate={endDate}
      />
    </Box>
  );
};

export default UsersAnalytics;
