import * as React from "react";
import { useLocation, Location, useNavigate } from "react-router-dom";
import SignUp from "./SignUp/SignUp";
import LogIn from "./LogIn/LogIn";
import { Box, Typography } from "@mui/material";
import LayerLogoDark from "../../assets/LayerLogoDark.svg";
import DashboardPreview from "../../assets/DashboardPreview.png";
import ResetPassword from "./ResetPassword/ResetPassword";
import Transfer from "./Transfer/Transfer";
import Demo from "./Demo/Demo";
import { useAuthContext } from "../../contexts/AuthContext";
import Verify from "./Verify/Verify";

interface AuthPageProps {
  path: string;
  pageTitle: string;
  children: React.ReactNode;
}

const authPageProps: AuthPageProps[] = [
  {
    path: "/login",
    pageTitle: "Layer - Log In",
    children: <LogIn />,
  },
  {
    path: "/signup",
    pageTitle: "Layer - Sign Up",
    children: <SignUp />,
  },
  {
    path: "/reset-password",
    pageTitle: "Layer - Reset Password",
    children: <ResetPassword />,
  },
  {
    path: "/transfer",
    pageTitle: "Layer - Transfer Account",
    children: <Transfer />,
  },
  {
    path: "/demo",
    pageTitle: "Layer - Demo",
    children: <Demo />,
  },
  {
    path: "/verify",
    pageTitle: "Layer - Verify Email",
    children: <Verify />,
  },
];

const Auth = () => {
  const user = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  function getPageFromLocation(location: Location<any>) {
    if (user && user.email && !user?.emailVerified)
      return authPageProps.find((page) => page.path === "/verify")!;
    if (location.pathname === "/") return authPageProps[0];
    const page = authPageProps.find(
      (page) => location.pathname === page.path && page.path !== "/verify"
    );

    if (!page) {
      navigate("/");
      return authPageProps[0];
    }

    return page;
  }

  const [page, setPage] = React.useState<AuthPageProps>(
    getPageFromLocation(location)
  );

  React.useEffect(() => {
    setPage(getPageFromLocation(location));
  }, [location, setPage]);

  React.useEffect(() => {
    document.title = page.pageTitle;
  }, [page]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          position: "absolute",
          top: "32px",
          left: "32px",
        }}
      >
        <img src={LayerLogoDark} alt={"Layer Logo"} style={{ width: "40px" }} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Typography variant="h1" sx={{ fontSize: "1.1rem" }}>
            Layer
          </Typography>
          <Typography variant="h1" sx={{ fontSize: "1rem", fontWeight: "300" }}>
            Integration Expert
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100vw",
          height: "100vh",
          alignItems: "stretch",
        }}
      >
        <Box
          sx={{
            minWidth: "60%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: page.path === "/demo" ? "100%" : "auto",
          }}
        >
          <Box
            sx={{
              width: page.path === "/demo" ? "100%" : "500px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "stretch",
              gap: 3,
              maxWidth: "800px",
            }}
          >
            {page.children}
          </Box>
        </Box>
        {page.path !== "/demo" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              pl: "5%",
              gap: 8,
              justifyContent: "end",
              alignItems: "start",
              bgcolor: "#7C7799",
              color: "white",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                pr: "5%",
              }}
            >
              <Typography variant="h2">
                Make your API easier to integrate
              </Typography>
              <Typography>
                Just upload your documentation and reference materials and we’ll
                deploy your Integration Expert to VS Code, Github Copilot, and
                more.
              </Typography>
            </Box>
            <img
              src={DashboardPreview}
              alt="Layer Management Platform Dashboard"
              style={{
                flexGrow: 1,
                width: "100%",
                maxHeight: "60%",
                objectFit: "cover",
                objectPosition: "top left",
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default Auth;
