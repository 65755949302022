import { DataGrid, GridRowId } from "@mui/x-data-grid";
import {
  Prompt2,
  usePromptsBatchDeletePromptsDeleteMutation,
  usePromptsGetPromptsGetQuery,
} from "../../../state/layerApi";
import { useState } from "react";
import DeleteConfirmationModal from "../../../shared/DeleteConfirmationModal";
import PromptsTableToolbar from "./PromptsTableToolbar";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import { PromptsTableColumns } from "./PromptsTableColumns";
import EditPromptModal from "./EditPromptModal";

const PromptsTable = () => {
  const { addMessage } = useSnackbarContext();
  const { data: prompts } = usePromptsGetPromptsGetQuery();
  const [deletePrompts] = usePromptsBatchDeletePromptsDeleteMutation();

  const [editPrompt, setEditPrompt] = useState<Prompt2 | undefined>(undefined);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteRows, setDeleteRows] = useState<number[]>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);

  function handleDeletePromptRows() {
    deletePrompts({ promptIds: deleteRows })
      .unwrap()
      .then(() => {
        addMessage("Prompts deleted", "success", 30);
      });
  }

  return (
    <>
      <DataGrid
        rows={prompts || []}
        columns={PromptsTableColumns(
          (id: number) => {
            const prompt = prompts?.find((prompt) => prompt.id === id);
            setEditPrompt(prompt);
          },
          (ids: number[]) => {
            setDeleteRows(ids);
            setDeleteModalOpen(true);
          }
        )}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnResize
        disableColumnSelector
        pageSizeOptions={[10, 25, 50, 100]}
        rowSelectionModel={selectedRowIds}
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRowIds([...newSelection]);
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              created_at: false,
              collection_id: false,
              updated_at: false,
            },
          },
          sorting: {
            sortModel: [{ field: "updated_at", sort: "desc" }],
          },
          pagination: {
            paginationModel: { pageSize: 10 },
          },
        }}
        slots={{
          toolbar: () => (
            <PromptsTableToolbar
              selectedRowIds={selectedRowIds}
              setSelectedRowIds={setSelectedRowIds}
            />
          ),
        }}
        sx={{ border: "none" }}
      />
      <DeleteConfirmationModal
        modalOpen={deleteModalOpen}
        itemName={
          deleteRows.length > 1 ? deleteRows.length + " Prompts" : "1 Prompt"
        }
        setOpen={setDeleteModalOpen}
        handleDelete={handleDeletePromptRows}
      />
      <EditPromptModal
        prompt={editPrompt}
        modalOpen={editPrompt !== undefined}
        handleClose={() => setEditPrompt(undefined)}
      />
    </>
  );
};

export default PromptsTable;
