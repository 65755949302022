import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { setDarkMode } from "../../state/themeSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import ChatIcon from "@mui/icons-material/Chat";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { signOut } from "firebase/auth";
import { auth } from "../../clients/firebase";
import { useCustomerContext } from "../../contexts/CustomerContext";
import { Chat } from "@buildwithlayer/embeddable-widget";
import ModalDialog from "../../shared/ModalDialog";
import { useState } from "react";

const Footer = ({ expanded }: { expanded: boolean }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { customer } = useCustomerContext();

  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  const [chatOpen, setChatOpen] = useState(false);

  // TODO: Add Logout confirmation dialog in demo mode

  return (
    <>
      <ModalDialog
        label="Test Chat"
        modalOpen={chatOpen}
        handleClose={() => setChatOpen(false)}
        hasDefaultWidth={false}
        styleOverrides={{ height: "80vh" }}
      >
        <Box
          sx={{
            borderRadius: 1,
            border: "1px solid",
            borderColor: "divider",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Chat
            apiKey={customer.api_key}
            apiUrl={process.env.REACT_APP_LAYER_URL}
            inContainer
            inModal={false}
            paletteMode={darkMode ? "dark" : "light"}
            cacheMessages
          />
        </Box>
      </ModalDialog>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: expanded ? 0 : 2,
          gap: expanded ? 0 : 2,
        }}
      >
        {expanded ? (
          <>
            <Box sx={{ display: "flex", gap: 2, p: 2 }}>
              <Button
                variant="contained"
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  p: 1,
                }}
                onClick={() => setChatOpen(true)}
              >
                <ChatIcon />
                Test Chat
              </Button>
              <Button
                onClick={() => dispatch(setDarkMode(!darkMode))}
                variant="outlined"
                sx={{ minWidth: 0, p: 1 }}
              >
                {darkMode ? <DarkModeIcon /> : <LightModeIcon />}
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 1,
                alignItems: "center",
                borderTop: "1px solid",
                borderColor: "divider",
                overflow: "hidden",
                p: 2,
              }}
            >
              {expanded && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    textOverlow: "ellipsis",
                  }}
                >
                  <Typography noWrap>{customer.company_name}</Typography>
                  <Typography variant="body2" noWrap>
                    {customer.email}
                  </Typography>
                </Box>
              )}
              <Tooltip
                title="Log out"
                placement="top"
                disableInteractive
                enterDelay={500}
              >
                <IconButton
                  onClick={() => {
                    signOut(auth).catch((err) => {
                      console.error(err);
                    });
                  }}
                >
                  <LogoutIcon sx={{ color: "#7B65FF" }} />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              sx={{
                flexGrow: 1,
                display: "flex",
                gap: 1,
                alignItems: "center",
                p: 2,
                minWidth: 0,
              }}
              onClick={() => setChatOpen(true)}
            >
              <ChatIcon />
            </Button>
            <Button
              onClick={() => dispatch(setDarkMode(!darkMode))}
              variant="outlined"
              sx={{ minWidth: 0, p: 2 }}
            >
              {darkMode ? <DarkModeIcon /> : <LightModeIcon />}
            </Button>
            <Tooltip
              title="Log out"
              placement="top"
              disableInteractive
              enterDelay={500}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{ display: "flex", justifyContent: "center", w: "100%" }}
              >
                <IconButton
                  onClick={() => {
                    signOut(auth).catch((err) => {
                      console.error(err);
                    });
                  }}
                  sx={{ width: "fit-content" }}
                >
                  <LogoutIcon sx={{ color: "#7B65FF" }} />
                </IconButton>
              </Box>
            </Tooltip>
          </>
        )}
      </Box>
    </>
  );
};

export default Footer;
