import { Box, Button, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import AddAPISpecModalContent from "../../Dashboard/Tools/AddAPISpecModalContent";
import { GridRowSelectionModel } from "@mui/x-data-grid";

const Demo = () => {
  const navigate = useNavigate();

  const [converted, setConverted] = React.useState(false);
  const [selectedRowIds, setSelectedRowIds] =
    React.useState<GridRowSelectionModel>([]);

  return (
    <>
      <Typography variant="h2">Upload OpenAPI Spec</Typography>
      <Typography>
        This will create a demo MCP server for you to test on. Your account will
        be deleted after 7 days unless you convert to a full account in the
        management dashboard.
      </Typography>
      {converted && (
        <Typography sx={{ fontWeight: "500" }}>
          Select endpoints to add as tools
        </Typography>
      )}
      <Box sx={{ maxHeight: "50vh", overflowY: "auto" }}>
        <AddAPISpecModalContent
          inDemoForm={true}
          converted={converted}
          setConverted={setConverted}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          flexGrow: 1,
        }}
      >
        <Typography sx={{ textAlign: "center", color: "#757575" }}>
          Already have an account?
        </Typography>
        <Button
          variant="text"
          onClick={() => navigate("/login")}
          sx={{ py: 0, px: "2px", minWidth: 0 }}
        >
          Log in
        </Button>
      </Box>
    </>
  );
};

export default Demo;
