import { Button, Typography } from "@mui/material";
import React from "react";
import ModalDialog from "../../../shared/ModalDialog";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailAndPasswordForm from "../EmailAndPasswordForm/EmailAndPasswordForm";
import {
  EmailAuthProvider,
  getAuth,
  linkWithCredential,
  sendEmailVerification,
} from "firebase/auth";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import { useNavigate } from "react-router-dom";

const Convert = () => {
  const navigate = useNavigate();
  const { addMessage } = useSnackbarContext();
  const auth = getAuth();

  const [open, setOpen] = React.useState(false);

  function handleConvert(email: string, password: string) {
    const credential = EmailAuthProvider.credential(email, password);
    if (auth.currentUser) {
      linkWithCredential(auth.currentUser, credential)
        .then((usercred) => {
          const user = usercred.user;
          user
            .getIdToken()
            .then((token: string) => {
              const url = `${process.env.REACT_APP_LAYER_URL}customers/assign`;
              const options = {
                method: "PATCH",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "Firebase-Auth": token,
                },
                body: JSON.stringify({ email: user.email }),
              };

              return fetch(url, options);
            })
            .then((resp) => {
              if (resp.status !== 200) {
                resp.json().then((data) => console.error(data));
                addMessage(
                  "Could not transfer customer account.",
                  "error",
                  500
                );
                throw Error("Could not transfer customer account.");
              } else {
                sendEmailVerification(user).then(() => {
                  addMessage(
                    "Account claimed successfully. Please verify your email.",
                    "success"
                  );
                  setOpen(false);
                  auth.updateCurrentUser(null);
                  auth.updateCurrentUser(user);
                  navigate("/verify");
                });
              }
            });
        })
        .catch((error) => {
          console.log("Error claiming anonymous account", error);
          addMessage("Error claiming anonymous account", "error");
        });
    }
  }

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        sx={{
          position: "fixed",
          bottom: 24,
          right: 24,
          zIndex: 1000,
          display: "flex",
          gap: 1,
          borderRadius: 1000,
          py: 1,
          px: 2,
        }}
        variant="contained"
      >
        <AccountCircleIcon />
        <Typography variant="button">Claim Account</Typography>
      </Button>
      <ModalDialog
        modalOpen={open}
        handleClose={() => setOpen(false)}
        label="Claim Account"
      >
        <EmailAndPasswordForm
          submitLabel="Claim Account"
          submit={handleConvert}
          loading={false}
        />
      </ModalDialog>
    </>
  );
};

export default Convert;
