import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import ModalDialog from "../../../shared/ModalDialog";
import {
  ToolCreate2,
  ToolsCreateToolPostApiArg,
  useToolsCreateToolPostMutation,
} from "../../../state/layerApi";
import ToolForm from "./ToolForm";

interface AddToolModalProps {
  modalOpen: boolean;
  handleClose: () => void;
}

const AddToolModal = ({ modalOpen, handleClose }: AddToolModalProps) => {
  const { addMessage } = useSnackbarContext();

  const [addTool] = useToolsCreateToolPostMutation();

  async function handleAddTool(values: ToolCreate2) {
    const toolCreate: ToolsCreateToolPostApiArg = {
      toolCreateInput: {
        name: values.name,
        description: values.description,
        method: values.method || "GET",
        url: values.url,
        auth: values.auth?.length ? values.auth : null,
        params: values.params?.length ? values.params : null,
        body: Object.keys(values.body || {}).length > 0 ? values.body : null,
        defs: Object.keys(values.defs || {}).length > 0 ? values.defs : null,
        strict: values.strict,
      },
    };

    try {
      addTool(toolCreate)
        .unwrap()
        .then(() => {
          addMessage("Tool added", "success");
          handleClose();
        })
        .catch((err) => {
          console.error(err);
          addMessage("Failed to add tool", "error");
        });
    } catch (err) {
      console.error(err);
      addMessage("Failed to add tool", "error");
    }
  }

  return (
    <ModalDialog
      label="Add Tool"
      modalOpen={modalOpen}
      handleClose={handleClose}
      hasDefaultWidth
      defaultWidth={600}
    >
      <ToolForm onSubmit={handleAddTool} submitButtonLabel="Add Tool" />
    </ModalDialog>
  );
};

export default AddToolModal;
