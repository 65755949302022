import { DataGrid, GridRowSelectionModel } from "@mui/x-data-grid";
import { ToolsTableColumns } from "./ToolsTableColumns";
import ToolsTableToolbar from "./ToolsTableToolbar";
import { useMemo, useState } from "react";
import EditToolModal from "./EditToolModal";
import {
  Tool2,
  useToolsDeleteToolDeleteMutation,
  useToolsGetToolsGetQuery,
} from "../../../state/layerApi";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";

interface ToolsTableProps {
  tools: Tool2[];
  setTools?: React.Dispatch<React.SetStateAction<Tool2[]>>;
  selectedRowIds: GridRowSelectionModel;
  setSelectedRowIds: React.Dispatch<
    React.SetStateAction<GridRowSelectionModel>
  >;
  inApiSpecModal?: boolean;
}

const ToolsTable = ({
  tools,
  setTools,
  selectedRowIds,
  setSelectedRowIds,
  inApiSpecModal = false,
}: ToolsTableProps) => {
  const { addMessage } = useSnackbarContext();

  const { data: existingTools } = useToolsGetToolsGetQuery();

  const toolRows = useMemo(() => {
    return tools?.map((tool) => ({
      ...tool,
      authenticated: tool.auth,
    }));
  }, [tools]);

  const [deleteTool] = useToolsDeleteToolDeleteMutation();

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [editingTool, setEditingTool] = useState<Tool2 | undefined>(undefined);

  function handleEditTool(id: string) {
    setEditingTool(tools?.find((tool) => tool.id === parseInt(id)));
    setEditModalOpen(true);
  }

  function handleDeleteTool(id: string) {
    deleteTool({ toolId: parseInt(id) })
      .unwrap()
      .then(() => {
        addMessage("Tool deleted", "success");
      });
  }

  return (
    <>
      <DataGrid
        rows={toolRows || []}
        columns={ToolsTableColumns({
          handleDelete: handleDeleteTool,
          handleEdit: handleEditTool,
          inApiSpecModal: inApiSpecModal,
        })}
        disableColumnResize
        rowSelectionModel={selectedRowIds}
        checkboxSelection
        disableRowSelectionOnClick
        pageSizeOptions={[10, 25, 50, 100]}
        onRowSelectionModelChange={(newSelection) =>
          setSelectedRowIds(() => {
            // if (!inApiSpecModal) return newSelection;
            // return newSelection.slice(0, 15 - (existingTools?.length || 0));
            return newSelection;
          })
        }
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        sx={{
          border: inApiSpecModal ? "1px solid" : "none",
          borderColor: "divider",
          maxHeight: "100%",
        }}
        slots={{
          toolbar: () => (
            <ToolsTableToolbar
              selectedRowIds={selectedRowIds}
              inApiSpecModal={inApiSpecModal}
              tools={tools}
            />
          ),
        }}
        isRowSelectable={(params) => {
          return true;
          // if (!inApiSpecModal) return true;
          // return (
          //   selectedRowIds.includes(params.id) ||
          //   selectedRowIds.length < 15 - (existingTools?.length || 0)
          // );
        }}
      />
      {editingTool && (
        <EditToolModal
          modalOpen={editModalOpen}
          handleClose={() => setEditModalOpen(false)}
          tool={editingTool}
          setTools={setTools}
          inApiSpecModal={inApiSpecModal}
        />
      )}
    </>
  );
};

export default ToolsTable;
