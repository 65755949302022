import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import copy from "copy-to-clipboard";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useCustomerContext } from "../../../contexts/CustomerContext";
import {
  useWebWidgetsGetWebWidgetsGetQuery,
  useWebWidgetsUpdateWidgetPutMutation,
  WebWidget,
} from "../../../state/layerApi";
import { useEffect, useState } from "react";
import { Edit, Save } from "@mui/icons-material";
import { useLazyDownloadWebWidgetLogoQuery } from "../../../state/emptyApi";
import Form, { Field } from "../../../shared/Form";
import ModalDialog from "../../../shared/ModalDialog";

const WebWidgetBaseField = ({
  fieldName,
  fieldLabel,
  fieldValidation,
  fieldHelperText,
}: {
  fieldName: keyof WebWidget;
  fieldLabel: string;
  fieldValidation?: (value: string) => string | null;
  fieldHelperText?: string;
}) => {
  const { addMessage } = useSnackbarContext();
  const { data: widgets, isLoading } = useWebWidgetsGetWebWidgetsGetQuery();

  const [value, setValue] = useState<string>("");
  const [editing, setEditing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [updateWidget, { isLoading: isSubmitLoading }] =
    useWebWidgetsUpdateWidgetPutMutation();

  useEffect(() => {
    if (widgets && fieldName in widgets?.[0] && widgets?.[0]?.[fieldName]) {
      setValue(widgets[0][fieldName] as string);
    }
  }, [widgets]);

  if (isLoading) {
    return <CircularProgress />;
  }

  function handleUpdateField() {
    if (!value) {
      setError("Field cannot be empty");
      return;
    } else if (error === "Field cannot be empty") {
      setError(null);
    }
    if (fieldValidation && fieldValidation(value)) {
      setError(fieldValidation(value));
      return;
    } else if (error && fieldValidation && !fieldValidation(value)) {
      setError(null);
    }
    const formData = new FormData();
    formData.append(fieldName, value);
    updateWidget({
      webWidgetId: widgets?.[0].id || 0,
      // @ts-ignore
      webWidgetUpdate: formData,
    })
      .unwrap()
      .then(() => setEditing(false))
      .catch(() => addMessage(`Failed to update ${fieldLabel}`, "error"));
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, width: "100%" }}>
      <TextField
        label={fieldLabel}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setError(null);
        }}
        sx={{ flexGrow: 1 }}
        variant="filled"
        disabled={!editing}
        error={Boolean(error)}
        helperText={error ? error : fieldHelperText}
      />
      {editing ? (
        isSubmitLoading ? (
          <CircularProgress />
        ) : (
          <IconButton onClick={handleUpdateField}>
            <Save />
          </IconButton>
        )
      ) : (
        <IconButton onClick={() => setEditing(!editing)}>
          <Edit />
        </IconButton>
      )}
    </Box>
  );
};

export const WebWidgetDisplayNameField = () => {
  return (
    <WebWidgetBaseField fieldLabel="Display Name" fieldName="product_name" />
  );
};

export const WebWidgetPrimaryColorField = () => {
  return (
    <WebWidgetBaseField
      fieldLabel="Primary Color"
      fieldName="primary_color"
      fieldHelperText="Must be a valid hex color code"
      fieldValidation={(value) => {
        if (!/^#([0-9A-F]{3}|[0-9A-F]{6})$/i.test(value)) {
          return "Invalid hex color code";
        } else return null;
      }}
    />
  );
};

export const WebWidgetLogoField = () => {
  const { addMessage } = useSnackbarContext();
  const { data: widgets, isLoading } = useWebWidgetsGetWebWidgetsGetQuery();
  const [downloadLogo, { isLoading: logoLoading }] =
    useLazyDownloadWebWidgetLogoQuery();

  const [logoData, setLogoData] = useState<string>("");
  const [editing, setEditing] = useState<boolean>(false);
  const [updateWidget, { isLoading: isSubmitLoading }] =
    useWebWidgetsUpdateWidgetPutMutation();

  function fetchLogo() {
    downloadLogo({ webWidgetId: widgets?.[0].id || 0 })
      .unwrap()
      .then((data) => {
        if (typeof data === "string") {
          setLogoData(data);
        } else if (data && typeof data === "object" && "data" in data) {
          setLogoData(data.data as string);
        } else {
          console.error("Unexpected data format:", data);
        }
      })
      .catch((error) => {
        console.error("Error downloading logo:", error);
      });
  }

  useEffect(() => {
    if (widgets?.[0] === undefined) return;

    fetchLogo();
  }, [downloadLogo, widgets]);

  if (isLoading || logoLoading || logoData === "") {
    return <CircularProgress />;
  }

  const editLogoFields: Field[] = [
    {
      fieldName: "logoData",
      fieldLabel: "Extension Icon",
      fieldType: "file",
      fieldRequired: false,
      fieldAcceptedFileExtensions: [".png", ".jpg"],
      fieldImageMinSize: { width: 128, height: 128 },
      fieldImageCrop: {
        aspectRatio: 1,
        minSize: { width: 128, height: 128 },
        rescaleImageWidth: 256,
      },
    },
  ];

  function handleUpdateLogo({ logoData }: { logoData: string }) {
    const formData = new FormData();
    formData.append("logo_file", logoData);
    updateWidget({
      webWidgetId: widgets?.[0].id || 0,
      // @ts-ignore
      webWidgetUpdate: formData,
    })
      .unwrap()
      .then(() => {
        setEditing(false);
        fetchLogo();
      })
      .catch(() => addMessage("Failed to update logo", "error"));
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          width: "100%",
        }}
      >
        <img src={logoData} alt="Logo" style={{ height: 100 }} />
        <IconButton onClick={() => setEditing(true)}>
          <Edit />
        </IconButton>
      </Box>
      <ModalDialog
        modalOpen={editing}
        handleClose={() => setEditing(false)}
        label="Update Logo"
      >
        <Form
          fields={editLogoFields}
          handleFormSubmit={(fields) => {
            console.log("fields", fields);
            handleUpdateLogo(fields as { logoData: string });
          }}
          submitButtonLoading={isSubmitLoading}
          submitButtonLabel="Update Logo"
        />
      </ModalDialog>
    </>
  );
};

export const WebWidgetInstallationInstructions = () => {
  const { addMessage } = useSnackbarContext();
  const { customer } = useCustomerContext();

  const script = `<script src="https://widget.buildwithlayer.com" type="text/javascript" id="layer-widget-script" api-key="${customer.api_key}"></script>`;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Typography>Insert this code before your closing head tag</Typography>
        <Button
          size="small"
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            px: 1.5,
            py: 1,
          }}
          onClick={() => {
            copy(script);
            addMessage("Copied to Clipboard", "success", 3);
          }}
        >
          <ContentCopyIcon fontSize="small" />
          Copy
        </Button>
      </Box>
      <pre style={{ margin: 0, whiteSpace: "pre-wrap", overflowX: "auto" }}>
        <code lang="html">{script}</code>
      </pre>
    </Box>
  );
};
