import { Box, Typography } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { transparentize } from "polished";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MethodTypeBadge from "./MethodTypeBadge";
import { MethodType } from "../../../state/layerApi";

interface ToolsTableColumnsProps {
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
  inApiSpecModal?: boolean;
}

export function ToolsTableColumns({
  handleEdit,
  handleDelete,
  inApiSpecModal = false,
}: ToolsTableColumnsProps): GridColDef[] {
  return [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "method",
      headerName: "Method",
      width: 96,
      renderCell: (params) => <MethodTypeBadge method={params.value} />,
    },
    {
      field: "url",
      headerName: "URL",
      flex: 2,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
    {
      field: "authenticated",
      headerName: "Authenticated",
      flex: 1,
      type: "boolean",
    },
    {
      field: "actions",
      width: 48,
      type: "actions",
      getActions: (params) => {
        return inApiSpecModal
          ? [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={() => handleEdit(params.id.toString())}
              />,
            ]
          : [
              <GridActionsCellItem
                icon={<EditIcon />}
                showInMenu
                label="Edit"
                onClick={() => handleEdit(params.id.toString())}
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                showInMenu
                label="Delete"
                onClick={() => handleDelete(params.id.toString())}
              />,
            ];
      },
    },
  ];
}
