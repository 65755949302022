import { Box } from "@mui/material";
import ToolsTable from "./ToolsTable";
import { useState } from "react";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { useToolsGetToolsGetQuery } from "../../../state/layerApi";

const Tools = () => {
  const { data: tools } = useToolsGetToolsGetQuery();

  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>(
    []
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid",
        borderColor: "divider",
        overflow: "hidden",
        borderRadius: 1,
      }}
    >
      <ToolsTable
        tools={tools || []}
        selectedRowIds={selectedRowIds}
        setSelectedRowIds={setSelectedRowIds}
      />
    </Box>
  );
};

export default Tools;
