import {
  GridRowId,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React from "react";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import { usePromptsBatchDeletePromptsDeleteMutation } from "../../../state/layerApi";
import { Box, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DeleteConfirmationModal from "../../../shared/DeleteConfirmationModal";
import AddWorkflowModal from "./AddPromptModal";

const PromptsTableToolbarButtons = ({
  selectedRowIds,
  setSelectedRowIds,
}: {
  selectedRowIds: GridRowId[];
  setSelectedRowIds: React.Dispatch<React.SetStateAction<GridRowId[]>>;
}) => {
  const { addMessage } = useSnackbarContext();
  const [deletePrompts, { isLoading: isDeleteLoading }] =
    usePromptsBatchDeletePromptsDeleteMutation();

  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = React.useState<boolean>(false);

  const handleDeleteWorkflowRows = () => {
    const promptIds = selectedRowIds.map((id) => id as number);
    deletePrompts({ promptIds: promptIds })
      .unwrap()
      .then(() => {
        addMessage(
          selectedRowIds.length > 1 ? "Workflows deleted" : "Workflow deleted",
          "success",
          30
        );
        setSelectedRowIds([]);
      })
      .catch((err) => {
        console.error(err);
        addMessage(
          selectedRowIds.length > 1
            ? "Error deleting workflows"
            : "Error deleting workflow",
          "error",
          60
        );
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          height: "48px",
        }}
      >
        <Button
          variant="outlined"
          disabled={selectedRowIds.length === 0 || isDeleteLoading}
          color="error"
          onClick={() => setDeleteModalOpen(true)}
          sx={{ minWidth: 0, width: "56px", height: "48px" }}
        >
          <DeleteIcon />
        </Button>
        <Button
          variant="contained"
          onClick={() => setAddModalOpen(true)}
          sx={{ minWidth: 0, width: "56px", height: "48px" }}
        >
          <AddIcon />
        </Button>
      </Box>
      <DeleteConfirmationModal
        modalOpen={deleteModalOpen}
        itemName={
          selectedRowIds.length > 1
            ? selectedRowIds.length + " Workflows"
            : "1 Workflow"
        }
        setOpen={setDeleteModalOpen}
        handleDelete={handleDeleteWorkflowRows}
      />
      <AddWorkflowModal
        modalOpen={addModalOpen}
        handleClose={() => setAddModalOpen(false)}
      />
    </>
  );
};

const PromptsTableToolbar = ({
  selectedRowIds,
  setSelectedRowIds,
}: {
  selectedRowIds: GridRowId[];
  setSelectedRowIds: React.Dispatch<React.SetStateAction<GridRowId[]>>;
}) => {
  return (
    <GridToolbarContainer>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          p: "12px",
          borderBottom: 1,
          borderColor: "divider",
          width: "100%",
        }}
      >
        <GridToolbarQuickFilter sx={{ flexGrow: 1 }} variant="filled" />
        <PromptsTableToolbarButtons
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
        />
      </Box>
    </GridToolbarContainer>
  );
};

export default PromptsTableToolbar;
