import { Box, Button, Typography } from "@mui/material";
import { sendEmailVerification } from "firebase/auth";
import { auth } from "../../../clients/firebase";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import { useNavigate } from "react-router-dom";

const Verify = () => {
  const { addMessage } = useSnackbarContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  async function handleResendVerificationEmail() {
    setLoading(true);
    try {
      if (auth.currentUser) await sendEmailVerification(auth.currentUser);
      else throw new Error("No user found.");
      addMessage("Verification email sent.", "success");
    } catch {
      addMessage("Could not send verification email.", "error");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Typography variant="h2">Verify Email</Typography>
      <Typography>
        You should receive a verification link to your email within a couple
        minutes. Refresh the page once you've clicked the link.
      </Typography>
      <LoadingButton
        variant="contained"
        loading={loading}
        onClick={handleResendVerificationEmail}
      >
        Send Again
      </LoadingButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          flexGrow: 1,
        }}
      >
        <Typography sx={{ textAlign: "center", color: "#757575" }}>
          Don't have an account yet?
        </Typography>
        <Button
          variant="text"
          onClick={() => {
            auth.updateCurrentUser(null);
            navigate("/signup");
          }}
          sx={{ py: 0, px: "2px", minWidth: 0 }}
        >
          Sign up
        </Button>
      </Box>
    </>
  );
};

export default Verify;
