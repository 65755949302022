import UploadFileIcon from "@mui/icons-material/UploadFile";
import Sources from "../components/Dashboard/Sources/Sources";
import HubIcon from "@mui/icons-material/Hub";
import React from "react";
import Analytics from "../components/Dashboard/analytics/Analytics";
import BarChartIcon from "@mui/icons-material/BarChart";
import TuneIcon from "@mui/icons-material/Tune";
import ConstructionIcon from "@mui/icons-material/Construction";
import RAGConfiguration from "../components/Dashboard/RAGConfiguration/RAGConfiguration";
import Tools from "../components/Dashboard/Tools/Tools";
import Deployments from "../components/Dashboard/Deployments/Deployments";
import Prompts from "../components/Dashboard/Prompts/Prompts";
import { Checklist } from "@mui/icons-material";

export interface PageProps {
  name: string;
  description: string;
  icon: React.JSX.Element;
  children: React.ReactNode;
  path: string;
  pageTitle: string;
  disableOnDemo?: boolean;
}

const pageProps: PageProps[] = [
  {
    name: "Resources",
    description:
      "Add & manage the resources your extensions reference to assist developers integrating your product.",
    icon: <UploadFileIcon />,
    children: <Sources />,
    path: "/resources",
    pageTitle: "Layer - Resources",
    disableOnDemo: true,
  },
  {
    name: "Prompts",
    description: "Create and manage prompts for your Integration Expert.",
    icon: <Checklist />,
    children: <Prompts />,
    path: "/prompts",
    pageTitle: "Layer - Prompts",
  },
  {
    name: "Tools",
    description:
      "Configure your API endpoints to be accessible from your deployed extensions.",
    icon: <ConstructionIcon />,
    children: <Tools />,
    path: "/tools",
    pageTitle: "Layer - Tools",
  },
  {
    name: "Deployments",
    description: "Manage your extension deployments.",
    icon: <HubIcon />,
    children: <Deployments />,
    path: "/deployments",
    pageTitle: "Layer - Deployments",
  },
  {
    name: "Analytics",
    description:
      "Get analytics on how developers are using your Integration Expert.",
    icon: <BarChartIcon />,
    children: <Analytics />,
    path: "/analytics",
    pageTitle: "Layer - Analytics",
    disableOnDemo: true,
  },
  {
    name: "RAG Configuration",
    description:
      "Make adjustments to our RAG system to optimize responses for your Integration Expert deployments.",
    icon: <TuneIcon />,
    children: <RAGConfiguration />,
    path: "/rag-configuration",
    pageTitle: "Layer - RAG Configuration",
    disableOnDemo: true,
  },
];

export default pageProps;
