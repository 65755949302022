import ModalDialog from "../../../../shared/ModalDialog";
import * as React from "react";
import Form, { Field } from "../../../../shared/Form";
import { FieldValues } from "react-hook-form";
import {
  SuggestionSourceCreate,
  useSourcesCreateDocumentSourcePostMutation,
  useSourcesCreateSuggestionSourcePostMutation,
  useCrawlsCrawlWebsitePostMutation,
  useSourcesCreateWebsiteSourcesPostMutation,
  WebsiteSourceCreate,
  layerApi,
  useSourcesGetSupportedTypesGetQuery,
  useCrawlsCrawlGithubPostMutation,
} from "../../../../state/layerApi";
import { Box } from "@mui/material";
import { useSnackbarContext } from "../../../../contexts/SnackbarContext";
import { useAppDispatch } from "../../../../state/hooks";
import SelectGithubSourcesModal from "./SelectGithubSourcesModal";

interface AddSourceModalProps {
  modalOpen: boolean;
  handleClose: () => void;
}

export default function AddSourceModal({
  modalOpen,
  handleClose,
}: AddSourceModalProps) {
  const { addMessage } = useSnackbarContext();
  const appDispatch = useAppDispatch();

  const [selectedType, setSelectedType] = React.useState<string>("");
  const [crawlSite, setCrawlSite] = React.useState<boolean>(false);

  const [githubModalOpen, setGithubModalOpen] = React.useState<boolean>(false);
  const [githubFilePaths, setGithubFilePaths] = React.useState<string[]>([]);
  const [githubRepoUrl, setGithubRepoUrl] = React.useState<string>("");

  const [postCrawlSite, { isLoading: isCrawlSiteLoading }] =
    useCrawlsCrawlWebsitePostMutation();
  const [postCrawlGithub, { isLoading: isCrawlGithubLoading }] =
    useCrawlsCrawlGithubPostMutation();
  const [postDocumentSource, { isLoading: isDocumentLoading }] =
    useSourcesCreateDocumentSourcePostMutation();
  const [postWebsiteSource, { isLoading: isWebsiteLoading }] =
    useSourcesCreateWebsiteSourcesPostMutation();
  const [postSuggestionSource, { isLoading: isSuggestionLoading }] =
    useSourcesCreateSuggestionSourcePostMutation();
  const { data: supportedFileExtensions } =
    useSourcesGetSupportedTypesGetQuery();

  const loading = [
    isCrawlSiteLoading,
    isCrawlGithubLoading,
    isDocumentLoading,
    isWebsiteLoading,
    isSuggestionLoading,
  ].some((l) => l);
  const ADD_SOURCE_FIELDS: Field[] = [
    // Common Fields
    {
      fieldName: "type",
      fieldLabel: "Source Type",
      fieldType: "select",
      fieldOptions: [
        { id: "document", label: "Document" },
        { id: "github", label: "GitHub Repo" },
        { id: "website", label: "Website" },
        { id: "suggestion", label: "Suggestion" },
      ],
      fieldRequired: true,
      fieldOnChange: (value: string) => {
        setSelectedType(value);
      },
    },
    {
      fieldName: "name",
      fieldLabel: "Name",
      fieldType: "text",
      fieldRequired: true,
    },
    // Document Sources
    {
      fieldName: "uploadType",
      fieldLabel: "Upload Type",
      fieldType: "select",
      fieldOptions: [
        { id: "file", label: "File" },
        { id: "url", label: "URL" },
      ],
      fieldRequired: true,
      fieldDependentOnName: "type",
      fieldDependentValue: "document",
    },
    {
      fieldName: "documentUrl",
      fieldLabel: "Document URL",
      fieldType: "text",
      fieldRequired: true,
      fieldDependentOnName: "uploadType",
      fieldDependentValue: "url",
      fieldValidation: (value: string) => {
        try {
          new URL(value);
        } catch {
          return "Please enter a valid Url.";
        }
      },
    },
    {
      fieldName: "file",
      fieldLabel: "Document",
      fieldType: "file",
      fieldRequired: true,
      fieldDependentOnName: "uploadType",
      fieldDependentValue: "file",
      fieldAcceptedFileExtensions: supportedFileExtensions?.types || [],
    },
    // GitHub Sources
    {
      fieldName: "repoUrl",
      fieldLabel: "Repo URL",
      fieldType: "text",
      fieldRequired: true,
      fieldDependentOnName: "type",
      fieldDependentValue: "github",
      fieldValidation: (value: string) => {
        try {
          new URL(value);
          if (!value.startsWith("https://github.com/")) throw new Error();
        } catch {
          return "Please enter a valid GitHub Repo Url.";
        }
      },
    },
    // Website Sources
    {
      fieldName: "url",
      fieldLabel: "Website",
      fieldType: "text",
      fieldRequired: true,
      fieldDependentOnName: "type",
      fieldDependentValue: "website",
      fieldValidation: (value: string) => {
        try {
          new URL(value);
        } catch {
          return "Please enter a valid Url.";
        }
      },
    },
    {
      fieldName: "crawlSetting",
      fieldLabel: "Crawl Setting",
      fieldType: "select",
      fieldOptions: [
        { id: "individual", label: "Individual Page" },
        { id: "crawl", label: "Crawl Website" },
      ],
      fieldRequired: true,
      fieldDependentOnName: "type",
      fieldDependentValue: "website",
      fieldHelperText:
        "Select whether to add an individual page or crawl the website",
      fieldOnChange: (value: string) => {
        if (value === "crawl") {
          setCrawlSite(true);
        } else {
          setCrawlSite(false);
        }
      },
    },
    {
      fieldName: "crawlFilters",
      fieldLabel: "Crawl Filters",
      fieldType: "autocomplete",
      fieldOptions: [],
      fieldRequired: false,
      fieldDependentOnName: "crawlSetting",
      fieldDependentValue: "crawl",
      fieldHelperText:
        "Enter patterns to match URLs to crawl (e.g. https://example.com/docs/*). Press enter after each filter. Leave blank to crawl all URLs.",
      multiple: true,
      freeSolo: true,
    },
    // Suggestion Sources
    {
      fieldName: "question",
      fieldLabel: "Question",
      fieldType: "text",
      fieldRequired: true,
      fieldDependentOnName: "type",
      fieldDependentValue: "suggestion",
    },
    {
      fieldName: "answer",
      fieldLabel: "Answer",
      fieldType: "text",
      fieldRequired: true,
      fieldMultiline: true,
      fieldDependentOnName: "type",
      fieldDependentValue: "suggestion",
    },
  ];

  async function handleDocumentSubmit(values: FieldValues) {
    const formData = new FormData();
    formData.set("name", values.name);
    if (values.uploadType === "url") {
      formData.set("url", values.documentUrl);
    } else {
      formData.set("file", values.file);
    }

    return postDocumentSource({
      // @ts-ignore
      documentSourceCreate: formData,
    })
      .unwrap()
      .then(() => {
        addMessage("Source Added", "success", 30);
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        addMessage("Error adding source", "error", 60);
      });
  }

  async function handleGithubCrawl(values: FieldValues) {
    return postCrawlGithub({
      crawlGitHubRequest: { url: values.repoUrl },
    })
      .unwrap()
      .then((res) => {
        setGithubFilePaths(res.file_paths);
        setGithubRepoUrl(values.repoUrl);
        setGithubModalOpen(true);
        handleClose();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async function handleWebsiteCrawl(values: FieldValues) {
    return postCrawlSite({
      crawlSiteRequest: {
        url: values.url as string,
        url_filters: values.crawlFilters || null,
      },
    })
      .unwrap()
      .then(() => {
        appDispatch(layerApi.util.invalidateTags(["crawls"]));
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        addMessage("Error starting crawl", "error", 60);
      });
  }

  async function handleWebsiteSubmit(values: FieldValues) {
    return postWebsiteSource({
      sources: [values as WebsiteSourceCreate],
    })
      .unwrap()
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        addMessage("Error adding source", "error", 60);
      });
  }

  async function handleSuggestionSubmit(values: FieldValues) {
    return postSuggestionSource({
      suggestionSourceCreate: values as SuggestionSourceCreate,
    })
      .unwrap()
      .then(() => {
        addMessage("Source Added", "success", 30);
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        addMessage("Error adding source", "error", 60);
      });
  }

  async function handleFormSubmit(values: FieldValues) {
    const sourceType = values.type;
    switch (sourceType) {
      case "document":
        return handleDocumentSubmit(values);
      case "github":
        return handleGithubCrawl(values);
      case "website":
        if (crawlSite) {
          return handleWebsiteCrawl(values);
        } else {
          return handleWebsiteSubmit(values);
        }
      case "suggestion":
        return handleSuggestionSubmit(values);
    }
  }

  return (
    <>
      <ModalDialog
        label={"Add Source"}
        modalOpen={modalOpen}
        handleClose={handleClose}
        defaultWidth={400}
      >
        <Box sx={{ display: "block" }}>
          <Form
            fields={ADD_SOURCE_FIELDS}
            handleFormSubmit={handleFormSubmit}
            submitButtonLabel={
              crawlSite && selectedType === "website"
                ? "Crawl Website"
                : "Add Source"
            }
            submitButtonLoading={loading}
          />
        </Box>
      </ModalDialog>
      <SelectGithubSourcesModal
        open={githubModalOpen}
        handleClose={() => {
          setGithubModalOpen(false);
          setGithubFilePaths([]);
          setGithubRepoUrl("");
        }}
        filePaths={githubFilePaths}
        repoUrl={githubRepoUrl}
      />
    </>
  );
}
