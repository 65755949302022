import * as React from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import Crawls from "./Sources/Crawls/Crawls";
import ScrapeStatus from "./Sources/ScrapeStatus/ScrapeStatus";
import pageProps, { PageProps } from "../../shared/Pages";
import SyncStatus from "./Sources/SyncStatus/SyncStatus";
import { useCustomerContext } from "../../contexts/CustomerContext";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { customer } = useCustomerContext();

  function getPageFromLocation(location: Location<any>) {
    if (!customer.email) {
      if (
        pageProps.find((page) => location.pathname === page.path)
          ?.disableOnDemo ||
        location.pathname === "/"
      ) {
        navigate("/tools");
        return pageProps[1];
      } else {
        return (
          pageProps.find((page) => location.pathname === page.path) ||
          pageProps[1]
        );
      }
    }

    if (location.pathname === "/") return pageProps[0];
    const page = pageProps.find((page) => location.pathname === page.path);

    if (!page) {
      navigate("/");
      return pageProps[0];
    }

    return page;
  }

  const [page, setPage] = React.useState<PageProps>(
    getPageFromLocation(location)
  );

  React.useEffect(() => {
    setPage(getPageFromLocation(location));
  }, [location, setPage]);

  React.useEffect(() => {
    document.title = page.pageTitle;
  }, [page]);

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {!customer.email && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "primary.main",
              color: "white",
              py: 1,
              position: "sticky",
              top: 0,
              zIndex: 999999999,
            }}
          >
            <Typography>
              Your account will be deleted 7 days after creation. Please claim
              your account to continue using Layer.
            </Typography>
          </Box>
        )}
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              py: 4,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              {page.icon}
              <Typography variant="h2">{page.name}</Typography>
            </Box>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              {page.description}
            </Typography>
            {page.children}
          </Box>
        </Container>
      </Box>
      <Crawls />
      {process.env.REACT_APP_NO_POLLING !== "true" && customer.email && (
        <ScrapeStatus />
      )}
      <SyncStatus />
    </>
  );
};

export default Dashboard;
