import { useMemo } from "react";
import { AnalyticsSectionProps, hashIp } from "./Analytics";
import {
  MethodType,
  Tool2,
  ToolLog,
  useToolLogsGetToolLogsGetQuery,
  useToolsGetToolsGetQuery,
} from "../../../state/layerApi";
import { GridColDef } from "@mui/x-data-grid";
import { formatDateTime } from "../../../utils/dateTime";
import { Box, CircularProgress, Typography } from "@mui/material";
import AnalyticsDataGrid from "./AnalyticsDataGrid";
import AnalyticsBarChart from "./AnalyticsBarChart";
import AnalyticsCard from "./AnalyticsCard";
import { faker } from "@faker-js/faker";

const ToolsAnalytics = ({
  startDate,
  endDate,
  dummyData = false,
}: AnalyticsSectionProps) => {
  const { data: actualToolLogs, isLoading: toolLogsLoading } =
    useToolLogsGetToolLogsGetQuery();
  const { data: actualTools, isLoading: toolsLoading } =
    useToolsGetToolsGetQuery();

  const fakeToolLogs: ToolLog[] = [];
  const fakeTools: Tool2[] = [];

  for (let i = 0; i < Math.floor(Math.random() * 350 + 2000); i++) {
    const toolId = Math.floor(Math.random() * 10);
    const statusCode = [200, 200, 200, 200, 200, 401, 404, 500][
      Math.floor(Math.random() * 8)
    ];
    fakeToolLogs.push({
      id: i,
      ip: faker.internet.ip(),
      tool_id: toolId,
      failure_message: statusCode === 200 ? null : faker.lorem.sentence(),
      client: [
        "Cursor",
        "Claude",
        "Windsurf",
        "Cursor",
        "Claude",
        "Windsurf",
        "VS Code",
        "Github Copilot",
      ][Math.floor(Math.random() * 8)],
      response_status_code: statusCode,
      created_at: faker.date.recent({ days: 180 }).toISOString().slice(0, -1),
      customer_id: 1,
    });
    fakeTools.push({
      id: toolId,
      name:
        ["GET", "POST", "PUT", "DELETE", "PATCH"][
          Math.floor(Math.random() * 5)
        ] +
        "_" +
        faker.lorem.word().toUpperCase(),
      description: faker.lorem.sentence(),
      customer_id: 1,
      url: faker.internet.url(),
      method: ["GET", "POST", "PUT", "DELETE", "Patch"][
        Math.floor(Math.random() * 5)
      ] as MethodType,
    });
  }

  const toolLogs = dummyData ? fakeToolLogs : actualToolLogs;
  const tools = dummyData ? fakeTools : actualTools;

  const filteredToolLogs = useMemo(() => {
    if (!toolLogs) {
      return [];
    }
    return toolLogs.filter((tool) => {
      const toolLogDate = new Date(tool.created_at + "Z");
      return toolLogDate >= startDate && toolLogDate <= endDate;
    });
  }, [toolLogs, startDate, endDate]);

  const toolLogRows = useMemo(() => {
    return filteredToolLogs.map((toolLog) => {
      const calledTool = tools?.find((t) => t.id === toolLog.tool_id);
      return {
        id: toolLog.id,
        userId: hashIp(toolLog.ip),
        toolName: calledTool?.name,
        failureMessage: toolLog.failure_message,
        client: toolLog.client,
        responseStatusCode: toolLog.response_status_code,
        timestamp: new Date(toolLog.created_at! + "Z"),
      };
    });
  }, [toolLogs, startDate, endDate, tools]);

  const toolLogChartData = useMemo(() => {
    let chartData: { data: { date: Date; value: number }[] }[] = [{ data: [] }];
    toolLogRows?.forEach((tool) => {
      chartData[0].data.push({ date: tool.timestamp, value: 1 });
    });
    return chartData;
  }, [toolLogRows, startDate, endDate]);

  const toolLogTableColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 200,
      type: "number",
    },
    { field: "toolName", headerName: "Tool Name", flex: 1, type: "string" },
    {
      field: "responseStatusCode",
      headerName: "Response Status Code",
      width: 100,
      type: "number",
      align: "center",
      renderCell: (params) => {
        if (params.value >= 200 && params.value < 300) {
          return (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  borderRadius: 1,
                  px: 1,
                  py: 0.5,
                  width: "fit-content",
                  bgcolor: "success.main",
                  color: "white",
                }}
              >
                {params.value}
              </Typography>
            </Box>
          );
        } else {
          return (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  borderRadius: 1,
                  px: 1,
                  py: 0.5,
                  width: "fit-content",
                  bgcolor: "error.main",
                  color: "white",
                }}
              >
                {params.value}
              </Typography>
            </Box>
          );
        }
      },
    },
    {
      field: "failureMessage",
      headerName: "Failure Message",
      flex: 1,
      type: "string",
    },
    {
      field: "client",
      headerName: "Client",
      width: 200,
      type: "string",
    },
    {
      field: "userId",
      headerName: "User ID",
      width: 150,
      type: "string",
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      width: 150,
      type: "dateTime",
      renderCell: (params) => {
        return formatDateTime(params.value);
      },
    },
  ];

  if (toolLogsLoading || toolsLoading || !toolLogs || !tools) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", gap: 2 }}>
        <AnalyticsCard
          label="Total Tool Calls"
          data={filteredToolLogs.length}
        />
      </Box>
      <AnalyticsBarChart
        chartData={toolLogChartData}
        startDate={startDate}
        endDate={endDate}
      />
      <AnalyticsDataGrid
        columns={toolLogTableColumns}
        rows={toolLogRows}
        tableName="ToolCalls"
        initialStateOverrides={{
          sorting: {
            sortModel: [{ field: "timestamp", sort: "desc" }],
          },
        }}
        startDate={startDate}
        endDate={endDate}
      />
    </Box>
  );
};

export default ToolsAnalytics;
