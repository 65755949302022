import React, { useState } from "react";
import Form, { Field } from "../../../shared/Form";
import ToolsTable from "./ToolsTable";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import {
  Tool2,
  ToolCreate,
  useCustomersCreateCustomerPostMutation,
  useToolsCreateToolsPostMutation,
} from "../../../state/layerApi";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import { LoadingButton } from "@mui/lab";
import { beforeAuthStateChanged, signInAnonymously, User } from "firebase/auth";
import { auth } from "../../../clients/firebase";
import { Box } from "@mui/material";

interface AddAPISpecModalProps {
  handleClose?: () => void;
  inDemoForm?: boolean;
  selectedRowIds: GridRowSelectionModel;
  setSelectedRowIds: React.Dispatch<
    React.SetStateAction<GridRowSelectionModel>
  >;
  converted: boolean;
  setConverted: (converted: boolean) => void;
}

interface AddAPISpecValues {
  uploadType: "file" | "url";
  url?: string;
  file?: File;
}

const AddAPISpecModalContent = ({
  handleClose,
  inDemoForm = false,
  selectedRowIds,
  setSelectedRowIds,
  converted,
  setConverted,
}: AddAPISpecModalProps) => {
  const { addMessage } = useSnackbarContext();

  const [loading, setLoading] = React.useState(false);

  const [tools, setTools] = React.useState<Tool2[]>([]);

  const [createCustomer] = useCustomersCreateCustomerPostMutation();

  const colorNames = ["red", "green", "blue", "yellow", "orange", "purple"];
  const randomColor = colorNames[Math.floor(Math.random() * colorNames.length)];
  const animalNames = [
    "dog",
    "cat",
    "bird",
    "fish",
    "rabbit",
    "hamster",
    "turtle",
    "snake",
    "lizard",
    "frog",
    "parrot",
    "chicken",
  ];
  const randomAnimal =
    animalNames[Math.floor(Math.random() * animalNames.length)];
  const companyName = `${randomColor}-${randomAnimal}-${Math.floor(
    Math.random() * 10000
  )}`;

  React.useEffect(() => {
    if (!inDemoForm) return;
    const cleanup = beforeAuthStateChanged(auth, (user: User | null) => {
      if (user === null || companyName === undefined) return;
      return user
        .getIdToken()
        .then((token: string) => {
          const url = `${process.env.REACT_APP_LAYER_URL}customers/`;
          const options = {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Firebase-Auth": token,
            },
            body: JSON.stringify({ company_name: companyName }),
          };

          return fetch(url, options);
        })
        .then((resp) => {
          if (resp.status !== 200) {
            resp.json().then((data) => console.error(data));
            addMessage("Could not create customer account.", "error", 500);
            throw Error("Could not create customer account.");
          }
        });
    });
    return () => cleanup();
  }, [companyName, createCustomer]);

  const [addTools, { isLoading: addToolsLoading }] =
    useToolsCreateToolsPostMutation();

  const ADD_API_SPEC_FIELDS: Field[] = [
    {
      fieldName: "uploadType",
      fieldLabel: "Upload Type",
      fieldType: "select",
      fieldOptions: [
        { id: "file", label: "File" },
        { id: "url", label: "URL" },
      ],
      fieldRequired: true,
      fieldDefaultValue: "file",
    },
    {
      fieldName: "url",
      fieldLabel: "API Spec URL",
      fieldType: "text",
      fieldRequired: true,
      fieldDependentOnName: "uploadType",
      fieldDependentValue: "url",
      fieldValidation: (value: string) => {
        try {
          new URL(value);
        } catch {
          return "Please enter a valid Url.";
        }
      },
    },
    {
      fieldName: "file",
      fieldLabel: "Upload API Spec File",
      fieldType: "file",
      fieldRequired: true,
      fieldDependentOnName: "uploadType",
      fieldDependentValue: "file",
      fieldAcceptedFileExtensions: [".yaml", ".json", ".yml"],
    },
  ];

  async function handleAddAPISpec(values: AddAPISpecValues) {
    setLoading(true);

    let file: File;
    if (values.uploadType === "file" && values.file !== undefined) {
      file = values.file;
    } else if (values.uploadType === "url" && values.url !== undefined) {
      try {
        const response = await fetch(values.url, { method: "GET" });
        if (!response.ok) {
          addMessage("Failed to fetch url", "error");
          throw new Error("Failed to fetch url");
        }
        const blob = await response.blob();
        if (
          blob.type !== "application/json" &&
          blob.type !== "text/yaml" &&
          !blob.type.startsWith("text/")
        ) {
          addMessage("Invalid file type", "error");
          throw new Error("Invalid file type");
        }
        file = new File([blob], "openapi.yaml");
      } catch (err) {
        setLoading(false);
        return;
      }
    } else {
      console.error("Invalid submission.");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_LAYER_URL}openapi/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error("Failed to parse openapi spec");
      }
      const responseJson = await response.json();
      const toolCreates = responseJson.tools as ToolCreate[];
      const tools = toolCreates.map((tool: ToolCreate, idx) => {
        return {
          ...tool,
          id: idx,
          customer_id: 0,
        } as Tool2;
      });
      setTools(tools);
      setConverted(true);
    } catch (err) {
      console.error(err);
      addMessage("Failed to parse openapi spec", "error");
    } finally {
      setLoading(false);
    }
  }

  async function handleAddTools() {
    // Create anonymous customer if in demo form
    if (inDemoForm) {
      try {
        await signInAnonymously(auth);
      } catch {
        addMessage("Failed to create anonymous account", "error");
        setLoading(false);
        return;
      }
    }

    try {
      addTools({
        tools: tools.filter((tool) => selectedRowIds.includes(tool.id)),
      });
      if (handleClose) handleClose();
      setSelectedRowIds([]);
      setConverted(false);
      addMessage("Tools added", "success");
    } catch {
      addMessage("Failed to add tools", "error");
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxHeight: "100%",
      }}
    >
      {converted ? (
        <>
          <ToolsTable
            tools={tools || []}
            selectedRowIds={selectedRowIds}
            setSelectedRowIds={setSelectedRowIds}
            inApiSpecModal={true}
            setTools={setTools}
          />
          <LoadingButton
            variant="contained"
            onClick={handleAddTools}
            disabled={selectedRowIds.length === 0}
            loading={addToolsLoading}
          >{`Add ${selectedRowIds.length} endpoint${
            selectedRowIds.length === 1 ? "" : "s"
          }`}</LoadingButton>
        </>
      ) : (
        <Form
          fields={ADD_API_SPEC_FIELDS}
          handleFormSubmit={(values) =>
            handleAddAPISpec(values as AddAPISpecValues)
          }
          submitButtonLoading={loading}
          submitButtonLabel="Generate Tools"
        />
      )}
    </Box>
  );
};

export default AddAPISpecModalContent;
