import ModalDialog from "../../../shared/ModalDialog";
import {
  PromptCreate,
  usePromptsCreatePromptPostMutation,
} from "../../../state/layerApi";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import PromptForm from "./PromptForm/PromptForm";

interface AddPromptModalProps {
  modalOpen: boolean;
  handleClose: () => void;
}

const AddPromptModal = ({ modalOpen, handleClose }: AddPromptModalProps) => {
  const { addMessage } = useSnackbarContext();
  const [addPrompt, { isLoading }] = usePromptsCreatePromptPostMutation();

  function handleAddPrompt(prompt: PromptCreate) {
    addPrompt({
      promptCreate: prompt,
    }).then(() => {
      addMessage("Prompt added", "success");
      handleClose();
    });
  }

  return (
    <ModalDialog
      label="Add Prompt"
      modalOpen={modalOpen}
      handleClose={handleClose}
      defaultWidth={800}
      styleOverrides={{ gap: 0 }}
    >
      <PromptForm
        handleSubmit={handleAddPrompt}
        isLoading={isLoading}
        submitButtonLabel="Add Prompt"
      />
    </ModalDialog>
  );
};

export default AddPromptModal;
